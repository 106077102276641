import React, { Component } from "react";
import UserDataService from "../../services/firebase_user.service";
import { Label, Input, Button } from "reactstrap";

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.onChangeId = this.onChangeId.bind(this);
    // this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePatronymic = this.onChangePatronymic.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRoles = this.onChangeRoles.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.AddUser = this.AddUser.bind(this);

    this.state = {
      currentUser: {
        id: null,
        username: "",
        first_name: "",
        last_name: "",
        patronymic: "",
        phone: "",
        password: "",
        healthy: true,
        roles: "driver",
        published: false,
      },
      message: "",
      IdIsValidated: true,
      // UserNameIsValidated: true,
      FirstNameIsValidated: true,
      LastNameIsValidated: true,
      PatronymicIsValidated: true,
      PhoneIsValidated: true,
      EmailIsValided: true,
      PasswordIsValidated: true,
      errorsId: "",
      errorsUserName: "",
      errorsFirstName: "",
      errorsLastName: "",
      errorsPatronymic: "",
      errorsPhone: "",
      errorsEmail: "",
    };
    this.unsubscribe = undefined;
  }

  componentDidMount() {
    this.setState({
      currentUser: { ...this.state.currentUser },
    });
    console.log("SetState=", this.state);
    console.log("SetState=", this.state.currentUser);
  }

  onChangeId(e) {
    const id = e.target.value;

    var pattern = new RegExp(/[^A-Za-z][_]/i);

    if (pattern.test(id)) {
      this.setState({
        IdIsValidated: false,
        errorsId: "Введите правильный ID",
      });
    } else if (typeof id != "undefined" && id.length < 4) {
      this.setState({
        IdIsValidated: false,
        errorsId:
          "введите идентификатор больче чем 4 латинских символа символа",
      });
    } else {
      this.setState({
        IdIsValidated: true,
        errorsId: "",
      });
    }

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          id: id,
        },
      };
    });
  }

  // onChangeUserName(e) {
  //   const username = e.target.value;

  //   if (typeof username != "undefined" && username.length > 1) {
  //     this.setState({
  //       UserNameIsValidated: true,
  //       errorsUserName: "",
  //     });
  //   } else {
  //     this.setState({
  //       UserNameIsValidated: false,
  //       errorsUserName: "введите болльше чем 1 символ",
  //     });
  //   }

  //   this.setState(function (prevState) {
  //     return {
  //       currentUser: {
  //         ...prevState.currentUser,
  //         username: username,
  //       },
  //     };
  //   });
  // }

  onChangeFirstName(e) {
    const first_name = e.target.value;

    if (typeof first_name != "undefined" && first_name.length > 1) {
      this.setState({
        FirstNameIsValidated: true,
        errorsFirstName: "",
      });
    } else {
      this.setState({
        FirstNameIsValidated: false,
        errorsFirstName: "введите болльше чем 1 символ",
      });
    }

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          first_name: first_name,
        },
      };
    });
  }

  onChangeLastName(e) {
    const last_name = e.target.value;

    if (typeof last_name != "undefined" && last_name.length > 1) {
      this.setState({
        LastNameIsValidated: true,
        errorsLastName: "",
      });
    } else {
      this.setState({
        LastNameIsValidated: false,
        errorsLastName: "введите болльше чем 1 символ",
      });
    }

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          last_name: last_name,
        },
      };
    });
  }

  onChangePatronymic(e) {
    const patronymic = e.target.value;

    if (typeof patronymic != "undefined" && patronymic.length > 1) {
      this.setState({
        PatronymicIsValidated: true,
        errorsPatronymic: "",
      });
    } else {
      this.setState({
        PatronymicIsValidated: false,
        errorsPatronymic: "введите болльше чем 1 символ",
      });
    }

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          patronymic: patronymic,
        },
      };
    });
  }

  onChangePhone(e) {
    const phone = e.target.value;

    if (typeof phone != "undefined" && phone.length > 1) {
      console.log(phone);
      this.setState({
        PhoneIsValidated: true,
        errorsPhone: "",
      });
    } else {
      this.setState({
        PhoneIsValidated: false,
        errorsPhone: "введите телефоный номер",
      });
    }

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        phone: phone,
      },
    }));
  }

  onChangeEmail(e) {
    const email = e.target.value;

    if (typeof email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(email)) {
        this.setState({
          EmailIsValided: false,
          errorsEmail: "Введите правильный адресс электронной почты",
        });
      } else {
        this.setState({
          EmailIsValided: true,
          errorsEmail: "",
        });
      }
    }

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        email: email,
      },
    }));
  }

  onChangeRoles(e) {
    const roles = e.target.value;

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        roles: roles,
      },
    }));
  }

  onChangePassword(e) {
    const password = e.target.value;

    if (typeof password != "undefined" && password.length > 8) {
      this.setState({
        PasswordIsValidated: true,
        errorsPassword: "",
      });
    } else {
      this.setState({
        PasswordIsValidated: false,
        errorsPassword: "пароль должен быть больше 8 символов",
      });
    }

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          password: password,
        },
      };
    });
  }

  updatePublished(status) {
    UserDataService.update(this.state.currentUser.id, {
      published: status,
    })
      .then(() => {
        this.setState((prevState) => ({
          currentUser: {
            ...prevState.currentUser,
            published: status,
          },
          message: "Статус был обновлен",
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  AddUser() {
    if (
      typeof this.state.currentUser === "undefined" ||
      this.state.currentUser.username === null
    ) {
      console.log("НЯМА currentUser");
      return;
    } else if (
      typeof this.state.currentUser.id === "undefined" ||
      this.state.currentUser.id.length < 4 ||
      this.state.IdIsValidated === false
    ) {
      console.log("id length <4");
      return;
      // } else if (typeof this.state.currentUser.username === 'undefined' || this.state.currentUser.username.length <= 1 || this.state.UserNameIsValidated === false) {
      //     console.log("username length <=1")
      //     return;
    } else if (
      typeof this.state.currentUser.last_name === "undefined" ||
      this.state.currentUser.last_name.length <= 1 ||
      this.state.LastNameIsValidated === false
    ) {
      console.log("last_name length <=1");
      return;
    } else if (
      typeof this.state.currentUser.first_name === "undefined" ||
      this.state.currentUser.first_name.length <= 1 ||
      this.state.FirstNameIsValidated === false
    ) {
      console.log("first_name length <=1");
      return;
    } else if (
      typeof this.state.currentUser.patronymic === "undefined" ||
      this.state.currentUser.patronymic.length <= 1 ||
      this.state.PatronymicIsValidated === false
    ) {
      console.log("last_name length <=1");
      return;
    } else if (
      typeof this.state.currentUser.email === "undefined" ||
      this.state.currentUser.email.length <= 1 ||
      this.state.EmailIsValided === false
    ) {
      console.log("email is not validated");
      return;
    } else if (
      typeof this.state.currentUser.phone === "undefined" ||
      this.state.currentUser.phone.length <= 4 ||
      this.state.PhoneIsValidated === false
    ) {
      console.log("no phone");
      return;
    } else if (
      typeof this.state.currentUser.roles === "undefined" ||
      this.state.currentUser.roles.length <= 1
    ) {
      console.log("no roles");
      return;
    } else if (
      typeof this.state.currentUser.password === "undefined" ||
      this.state.currentUser.password.length <= 8 ||
      this.state.PasswordIsValidated === false
    ) {
      console.log("no password or password should be more then 8 characters");
      return;
    } else if (typeof this.state.currentUser.healthy === "undefined") {
      console.log("no healthy");
      return;
    } else {
      const id = this.state.currentUser.id;

      const data = {
        username: this.state.currentUser.username,
        first_name: this.state.currentUser.first_name,
        last_name: this.state.currentUser.last_name,
        patronymic: this.state.currentUser.patronymic,
        email: this.state.currentUser.email,
        phone: this.state.currentUser.phone,
        roles: this.state.currentUser.roles,
        password: this.state.currentUser.password,
        healthy: this.state.currentUser.healthy,
      };

      UserDataService.create_with_id(id, data)
        .then(() => {
          this.setState({
            message: "Пользователь " + id + " успешно добавлен",
          });
          this.props.history.push("/listusers");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  render() {
    let inputStyleId = { border: "1px solid black" };
    // let inputStyleUserName = { border: "1px solid black" };
    let inputStyleFirstName = { border: "1px solid black" };
    let inputStyleLastName = { border: "1px solid black" };
    let inputStylePatronymic = { border: "1px solid black" };
    let inputStylePhone = { border: "1px solid black" };
    let inputStyleEmail = { border: "1px solid black" };
    let inputStylePassword = { border: "1px solid black" };

    const IdIsValidated = this.state.IdIsValidated;
    const errorsId = this.state.errorsId;

    // const UserNameIsValidated = this.state.UserNameIsValidated;
    // const errorsUserName = this.state.errorsUserName;

    const FirstNameIsValidated = this.state.FirstNameIsValidated;
    const errorsFirstName = this.state.errorsFirstName;

    const LastNameIsValidated = this.state.LastNameIsValidated;
    const errorsLastName = this.state.errorsLastName;

    const PatronymicIsValidated = this.state.PatronymicIsValidated;
    const errorsPatronymic = this.state.errorsPatronymic;

    const PhoneIsValidated = this.state.PhoneIsValidated;
    const errorsPhone = this.state.errorsPhone;

    const EmailIsValided = this.state.EmailIsValided;
    const errorsEmail = this.state.errorsEmail;

    const PasswordIsValidated = this.state.PasswordIsValidated;
    const errorsPassword = this.state.errorsPassword;

    if (IdIsValidated) {
      inputStyleId = { border: "1px solid black" };
    } else {
      inputStyleId = { border: "1px solid red" };
    }

    // if (UserNameIsValidated) {
    //   inputStyleUserName = { border: "1px solid black" };
    // } else {
    //   inputStyleUserName = { border: "1px solid red" };
    // }

    if (FirstNameIsValidated) {
      inputStyleFirstName = { border: "1px solid black" };
    } else {
      inputStyleFirstName = { border: "1px solid red" };
    }

    if (LastNameIsValidated) {
      inputStyleLastName = { border: "1px solid black" };
    } else {
      inputStyleLastName = { border: "1px solid red" };
    }

    if (PatronymicIsValidated) {
      inputStylePatronymic = { border: "1px solid black" };
    } else {
      inputStylePatronymic = { border: "1px solid red" };
    }

    if (PhoneIsValidated) {
      inputStylePhone = { border: "1px solid black" };
    } else {
      inputStylePhone = { border: "1px solid red" };
    }

    if (EmailIsValided) {
      inputStyleEmail = { border: "1px solid black" };
    } else {
      inputStyleEmail = { border: "1px solid red" };
    }

    if (PasswordIsValidated) {
      inputStylePassword = { border: "1px solid black" };
    } else {
      inputStylePassword = { border: "1px solid red" };
    }

    return (
      <div>
        <br></br>
        <h5>Добавить пользователя</h5>
        <div className="edit-form">
          <form onSubmit={this.AddUser}>
            <div className="form-group">
              <label htmlFor="id">
                Уникальный индефикатор пользователя, должен быть заполнен
                латинскими буквами через нижнее подчеркивание. Пример
                Vasya_Pupkin
              </label>
              <input
                type="text"
                style={inputStyleId}
                className="form-control"
                placeholder="Vasya_Pupkin"
                id="id"
                minLength={4}
                value={this.state.id}
                onChange={this.onChangeId}
              />
              <span className="error">{errorsId}</span>
              <div></div>
            </div>
            {/* <div className="form-group">
                            <label htmlFor="username">ФИО пользователя</label>
                            <input
                                type="text"
                                style={inputStyleUserName}
                                className="form-control"
                                id="username"
                                minLength={1}
                                value={this.state.username}
                                onChange={this.onChangeUserName}
                            />
                            <span className='error'>{errorsUserName}</span>
                            <div></div>
                        </div> */}
            <div className="form-group">
              <label htmlFor="last_name">Фамилия</label>
              <input
                type="text"
                style={inputStyleLastName}
                className="form-control"
                id="last_name"
                minLength={1}
                value={this.state.last_name}
                onChange={this.onChangeLastName}
              />
              <span className="error">{errorsLastName}</span>
              <div></div>
            </div>
            <div className="form-group">
              <label htmlFor="first_name">Имя</label>
              <input
                type="text"
                style={inputStyleFirstName}
                className="form-control"
                id="first_name"
                minLength={1}
                value={this.state.first_name}
                onChange={this.onChangeFirstName}
              />
              <span className="error">{errorsFirstName}</span>
              <div></div>
            </div>
            <div className="form-group">
              <label htmlFor="patronymic">Отчество</label>
              <input
                type="text"
                style={inputStylePatronymic}
                className="form-control"
                id="patronymic"
                minLength={1}
                value={this.state.patronymic}
                onChange={this.onChangePatronymic}
              />
              <span className="error">{errorsPatronymic}</span>
              <div></div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email пользователя</label>
              <input
                type="text"
                style={inputStyleEmail}
                className="form-control"
                id="email"
                minLength={5}
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <span className="error">{errorsEmail}</span>
              <div></div>
            </div>
            <div className="form-group">
              <Label for="roles">Роль</Label>
              <Input
                type="select"
                name="select"
                id="roles"
                value={this.state.roles}
                defaultValue="driver"
                onChange={this.onChangeRoles}
              >
                <option>client</option>
                <option>driver</option>
                <option>manager</option>
              </Input>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Телефон</label>
              <input
                type="number"
                pattern="[+-]?\d+(?:[.,]\d+)?"
                style={inputStylePhone}
                placeholder="Введите телефон пользователя"
                className="form-control"
                id="min_work_hours"
                minLength={7}
                maxLength={12}
                value={this.state.phone}
                onChange={this.onChangePhone}
              />
              <span className="error">{errorsPhone}</span>
            </div>
            <div className="form-group">
              <label htmlFor="password">Пароль пользователя</label>
              <input
                type="password"
                style={inputStylePassword}
                className="form-control"
                id="password"
                minLength={1}
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <span className="error">{errorsPassword}</span>
              <div></div>
            </div>
            <div className="form-group">
              <label>
                <strong>Статус:</strong>
              </label>
            </div>
          </form>{" "}
          <Button color="secondary" className="btn-sm" onClick={this.AddUser}>
            Добавить
          </Button>{" "}
          <p>{this.state.message}</p>
        </div>
      </div>
    );
  }
}
