import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Row } from 'react-bootstrap';

import db from '../data/config';
import "./scroller.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00cc36',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#cf000f',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#cf000f',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    maxWidth: 345,
  },
  media: { height: 140, }
}));

export default function NestedGrid() {

  // const [count, setCount] = useState(5);

  const [carList, setCarList] = React.useState([]);

  const [CarGroupStatus, setCarGroupStatus] = React.useState("all_trucks");

  // const [error, setError] = React.useState(false)

  // TODO: Add loader
  // const [loading, setLoading] = React.useState(true)


  useEffect(() => {
    const subscriber = db
      .collection('cars')
      .onSnapshot(querySnapshot => {
        const carList = [];
        querySnapshot.forEach(documentSnapshot => {
          carList.push({
            ...documentSnapshot.data(),
            id: documentSnapshot.id,
          });
        });

        setCarList(carList);
        // setLoading(false);
      });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);


  const handleChange = id => e => {
    const index = carList.findIndex(element => element.id === id)
    const car_id = id
    let newarr = carList[index] = { ...carList[index], healthy: !carList[index].healthy }
    UpdateCar(car_id, newarr);
  };

  function imageClick(car_group) {
    setCarGroupStatus(car_group);
  }

  function UpdateCar(car_id, car_object) {
    delete car_object.id;
    db.collection('cars').doc(car_id).update(car_object);
  }

  function CarCard({ id, brand, plate_number, car_group, car_type, image_path, default_driver, uuidDrivers, healthy }) {
    const classes = useStyles();
    return (
      <Card className={classes.card} >
        <CardActionArea >
          <CardMedia className={classes.media} image={image_path} />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p"> {id} </Typography>
            <Typography gutterBottom variant="h5" component="h2"> {car_group + " " + brand} </Typography>
            <Typography variant="body2" color="textSecondary" component="p">Бренд: {brand} </Typography>
            <Typography variant="body2" color="textSecondary" component="p">Тип: {car_type} </Typography>
            <Typography variant="body2" color="textSecondary" component="p">Номер: {plate_number} </Typography>
            <Typography variant="body2" color="textSecondary" component="p">---------</Typography>
            <Typography variant="body2" color="textSecondary" component="p">Основной водитель: {default_driver} </Typography>
            <Typography variant="body2" color="textSecondary" component="p">Дополнительные водители: {uuidDrivers && uuidDrivers.join(', ')} </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Typography variant="body2" color="textSecondary" component="p"> НА РЕМОНТЕ </Typography>
          {/* <Button href={image_path} size="small" color="primary">Полный размер</Button> */}
          <ThemeProvider theme={theme}>
            <Switch
              key={id}
              checked={healthy}
              color={healthy ? "primary" : "secondary"} // red is not working
              onChange={handleChange(id)}
              name="healthy"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </ThemeProvider>
          <Typography variant="body2" color="textSecondary" component="p"> ИСПРАВНАЯ </Typography>
        </CardActions>
      </Card>);
  }

  return (
    <div>
      <h5>Список техники для механика</h5>
      <Row >
        <button style={{ border: CarGroupStatus === "lift_truck" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/lift_truck.svg" height="65px" alt="lift_truck" onClick={() => imageClick("lift_truck")} /></button>
        <button style={{ border: CarGroupStatus === "eks_pogruzhik_kolesniy" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/eks_pogruzhik_kolesniy.svg" height="65px" alt="eks_pogruzhik_kolesniy" onClick={() => imageClick("eks_pogruzhik_kolesniy")} /></button>
        <button style={{ border: CarGroupStatus === "perevozka_light" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/perevozka_light.svg" height="65px" alt="perevozka_light" onClick={() => imageClick("perevozka_light")} /></button>
        <button style={{ border: CarGroupStatus === "pogruzhik" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/pogruzhik.svg" height="65px" alt="pogruzhik" onClick={() => imageClick("pogruzhik")} /></button>
        <button style={{ border: CarGroupStatus === "road_tools" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/road_tools.svg" height="65px" alt="road_tools" onClick={() => imageClick("road_tools")} /></button>
        <button style={{ border: CarGroupStatus === "eks_povorotniy" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/eks_povorotniy.svg" height="65px" alt="eks_povorotniy" onClick={() => imageClick("eks_povorotniy")} /></button>
        <button style={{ border: CarGroupStatus === "perevozka_10_20_30t" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/perevozka_10_20_30t.svg" height="65px" alt="perevozka_10_20_30t" onClick={() => imageClick("perevozka_10_20_30t")} /></button>
        <button style={{ border: CarGroupStatus === "all_trucks" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/all_trucks.svg" height="65px" alt="all_trucks" onClick={() => imageClick("all_trucks")} /></button>
      </Row>
      <Grid container spacing={5}
        style={{ padding: '24px' }}
      >
        {carList &&
          (CarGroupStatus === 'all_trucks' ? carList : carList.filter(e => e.car_group === CarGroupStatus))
            .map((s, index) =>
              <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3} >
                <CarCard
                  id={s.id}
                  key={s.id}
                  brand={s.brand}
                  plate_number={s.plate_number}
                  car_group={s.car_group}
                  car_type={s.car_type}
                  image_path={s.image_path}
                  default_driver={s.default_driver}
                  uuidDrivers={s.uuidDrivers}
                  healthy={s.healthy}
                />
              </Grid>)}
      </Grid>
    </div>)
}
