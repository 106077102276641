import React, { Component } from "react";
import OrderDataService from "../services/firebase_orders.service";
import Order from "../components/order/order";
import CarDataService from "../services/firebase.service";
import { Container, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import "./scroller.css";

export default class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveOrder = this.setActiveOrder.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.handleSelectTicketStatus = this.handleSelectTicketStatus.bind(this);
    this.imageClick = this.imageClick.bind(this);

    this.state = {
      ticketStatus: {
        created: false,
        approved: false,
        in_progress: false,
        rejected: false,
        done: false,
        all: true,
      },
      CarGroupStatus: {
        lift_truck: false,
        eks_pogruzhik_kolesniy: false,
        perevozka_light: false,
        pogruzhik: false,
        perevozka_10_20_30t: false,
        road_tools: false,
        eks_povorotniy: false,
      },
      orders: [],
      cars: [],
      carsReady: false,
      currentOrder: null,
      filterStatus: "all",
      filterCarGroup: "perevozka_10_20_30t",
      currentIndex: -1,
    };

    this.unsubscribe = undefined;
  }

  componentDidMount() {
    console.log("Did Mount");
    this.unsubscribe = OrderDataService.getAll()
      .orderBy("approximateEndDateTime", "desc")
      .onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items) {
    let orders = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();

      var date_options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var time_options = { hour: "2-digit", minute: "2-digit" };

      const timestamp = data.requestedDateTime.toDate();

      orders.push({
        id: id, // id of the order/ticket
        address: data.address,
        comments: data.comments,
        driverUuid: data.driverUuid,
        createdBy: data.createdBy,
        createdDate: data.createdDate,
        createdByName: data.createdByName,
        approvedBy: data.approvedBy,
        approvedByName: data.approvedByName,
        approvedDate: data.approvedDate,
        completedBy: data.completedBy,
        completedByName: data.completedByName,
        completedDate: data.completedDate,
        startedBy: data.startedBy,
        startedByName: data.startedByName,
        startedDate: data.startedDate,
        startTime: data.startTime,
        endTime: data.endTime,
        time: timestamp.toLocaleTimeString([], time_options), // 15:00
        timeWork: data.timeWork,
        ticketNumber: data.ticketNumber,
        requestedDateTime: data.requestedDateTime.toDate(), // вторник, 2 марта 2021 г.
        date: timestamp.toLocaleDateString("ru-Ru", date_options),
        carUuid: data.carUuid,
        status: data.status,
        image_path:
          "https://storage.googleapis.com/dzetruck-cars/cars/no_vehicle.png",
      });
    });

    this.setState({
      orders: orders,
    });

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();

      if (data.carUuid !== null) {
        CarDataService.get_data(data.carUuid).then((doc) => {
          const carGroup = doc.data().car_group;
          const imagePath = doc.data().image_path;
          const PlateNumber = doc.data().plate_number;
          const order = orders.filter((x) => x.id === item.id);

          order.carGroup = carGroup;
          order.imagePath = imagePath;
          order.PlateNumber = PlateNumber;
          order.id = item.id;

          //Find index of specific object using findIndex method.
          const objIndex = orders.findIndex((obj) => obj.id === id);

          //Update object's image_path property.
          orders[objIndex].image_path = order.imagePath;
          orders[objIndex].plate_number = order.PlateNumber;
          orders[objIndex].car_group = order.carGroup;

          order.imageIsLoaded = true;
          const notLoadedOrder = orders.filter((x) => !x.imageIsLoaded);

          this.setState({
            orders: orders,
          });

          if (!notLoadedOrder) {
            this.setState({
              carsReady: true,
            });
          }
        });
      } else console.log("conflict order", id);
    });
  }

  refreshList() {
    this.setState({
      currentOrder: null,
      currentIndex: -1,
    });
  }

  setActiveOrder(order, index) {
    this.setState({
      currentOrder: order,
      currentIndex: index,
    });
  }

  imageClick(car_group) {
    this.setState({
      filterCarGroup: car_group,
    });
  }

  handleSelectTicketStatus(e) {
    this.setState({
      ticketStatus: {
        e: true,
      },
      filterStatus: e,
    });

    this.refreshList();
  }

  render() {
    const { orders, currentOrder, currentIndex, filterStatus } = this.state;

    const styles = {
      backgroundColor: "#CC9933",
      padding: "3px",
      color: "white",
    };

    const cardTextStyle = {
      marginBottom: 0,
      // "lineHeight": ""
    };

    const titleStyle = {
      marginBottom: 0,
      fontWeight: "bold",
    };

    const buttonStyle = {
      margin: "5px",
    };

    const count_of_approved_tickets = orders.filter(
      (s) => s.status === "approved"
    ).length;
    const count_of_created_tickets = orders.filter(
      (s) => s.status === "created"
    ).length;
    const count_of_in_progress_tickets = orders.filter(
      (s) => s.status === "in_progress"
    ).length;
    const count_of_done_tickets = orders.filter(
      (s) => s.status === "done"
    ).length;
    const count_of_rejected_tickets = orders.filter(
      (s) => s.status === "rejected"
    ).length;
    const count_all_tickets = orders.length;

    return (
      <div>
        <br></br>
        <br></br>
        <h5>Список заказов</h5>
        <div className="list row">
          <Col xl="5">
            <Row>
              <Button
                onClick={() => this.handleSelectTicketStatus("created")}
                style={buttonStyle}
                size="md "
                variant={
                  this.state.filterStatus === "created"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                созданные {count_of_created_tickets}
              </Button>{" "}
              <Button
                onClick={() => this.handleSelectTicketStatus("approved")}
                style={buttonStyle}
                size="md"
                variant={
                  this.state.filterStatus === "approved"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                одобренные {count_of_approved_tickets}
              </Button>{" "}
              <Button
                onClick={() => this.handleSelectTicketStatus("rejected")}
                style={buttonStyle}
                size="md"
                variant={
                  this.state.filterStatus === "rejected"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                отколоненные {count_of_rejected_tickets}
              </Button>{" "}
              <Button
                onClick={() => this.handleSelectTicketStatus("in_progress")}
                style={buttonStyle}
                size="md"
                variant={
                  this.state.filterStatus === "in_progress"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                в процессе {count_of_in_progress_tickets}
              </Button>{" "}
              <Button
                onClick={() => this.handleSelectTicketStatus("done")}
                style={buttonStyle}
                size="md"
                variant={
                  this.state.filterStatus === "done"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                выполненые {count_of_done_tickets}
              </Button>{" "}
              <Button
                onClick={() => this.handleSelectTicketStatus("all")}
                style={buttonStyle}
                size="md"
                variant={
                  this.state.filterStatus === "all"
                    ? "outline-dark active"
                    : "outline-dark"
                }
              >
                показать все {count_all_tickets}
              </Button>{" "}
            </Row>
            <div className="scroller">
              <br></br>
              {orders &&
                (filterStatus === "all"
                  ? orders
                  : orders.filter((e) => e.status === filterStatus)
                ).map((order, index) => (
                  <Card
                    key={index}
                    className={
                      "list-group-item " +
                      (index === currentIndex
                        ? "list-group-item-action list-group-item-warning"
                        : "")
                    }
                    style={{ "margin-bottom": "10px", padding: 0 }}
                    onClick={() => this.setActiveOrder(order, index)}
                  >
                    <Card.Header style={styles}>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          {order.createdByName}
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                          заказ:{order.ticketNumber}
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body style={{ padding: 0 }}>
                      <Container style={{ margin: "0px" }}>
                        <Row>
                          <Card.Img
                            style={{
                              paddingLeft: 0,
                              margin: "0 0 5px 0",
                              width: "200px",
                            }}
                            src={order.image_path}
                          />
                          <Col>
                            <Row>
                              <Col align="left">
                                <Card.Text
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                  }}
                                  align="left"
                                >
                                  водитель:
                                </Card.Text>
                                <Card.Text style={cardTextStyle} align="left">
                                  {order.driverUuid}
                                </Card.Text>
                                <Card.Text
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                  }}
                                  align="left"
                                >
                                  номерной знак:
                                </Card.Text>
                                <Card.Text style={cardTextStyle}>
                                  {order.plate_number}
                                </Card.Text>
                              </Col>
                              <Col align="right">
                                <Card.Text
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    marginBottom: "0px",
                                    textAlign: "right",
                                  }}
                                  align="right"
                                >
                                  заказчик:
                                </Card.Text>
                                <Card.Text style={cardTextStyle} align="right">
                                  {order.createdByName}
                                </Card.Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col align="left">
                            <Card.Text style={titleStyle}>адрес:</Card.Text>
                            <Card.Text style={cardTextStyle}>
                              {order.address}
                            </Card.Text>
                            <Card.Text style={titleStyle}>
                              Комментарии
                            </Card.Text>
                            <Card.Text style={cardTextStyle}>
                              {order.comments}
                            </Card.Text>
                          </Col>
                          <Col align="right">
                            <Card.Text style={titleStyle}>
                              бронь на {order.time}
                            </Card.Text>
                            <Card.Text style={titleStyle}>
                              {order.date}
                            </Card.Text>
                            <Card.Text style={cardTextStyle}>
                              планируемое время работы:{order.timeWork} ч
                            </Card.Text>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                  </Card>
                ))}
            </div>
          </Col>
          <Col>
            {currentOrder ? (
              <Order order={currentOrder} refreshList={this.refreshList} />
            ) : (
              <div>
                <br />
                <p>Выберите заказ</p>
              </div>
            )}
          </Col>
        </div>
      </div>
    );
  }
}
