import firebase from "../data/config";

const db = firebase.collection("/user");

class DataService {
  getAll() {
    return db;
  }

  get_all_by_role(role) {
    return db.where('roles', '==', role)
  }

  get_data(user_id) {
    return db.doc(user_id).get();
  }

  get_image_path(driver) {
    return db.doc(driver).get();
  }

  create(driver) {
    return db.add(driver);
  }

  create_with_id(id, user) {
    return db.doc(id).set(user);
  }

  update(id, value) {
    return db.doc(id).update(value);
  }

  delete(id) {
    return db.doc(id).delete();
  }
}

export default new DataService();
