import React, { Component } from "react";
import { Container, Row, Col } from 'reactstrap';
import CarDataService from "../services/firebase.service";
import UserDataService from "../services/firebase_user.service";
import Car from "../components/car/car";
import { Button } from 'reactstrap'
import { Card } from 'react-bootstrap';
import "./scroller.css";

export default class CarsList extends Component {
  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveCar = this.setActiveCar.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.handleAddCarClick = this.handleAddCarClick.bind(this);
    this.imageClick = this.imageClick.bind(this);

    this.state = {
      drivers: [],
      cars: [],
      uuidDrivers: [],
      currentCar: null,
      currentIndex: -1,
      CarGroupStatus: "all_trucks",
      filterCarGroup: ""
    };

    this.unsubscribe = undefined;

    this.drivers = [];

    UserDataService.get_all_by_role("driver").orderBy("username", "asc").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.drivers.push({
          id: doc.id,
          username: doc.data().username,
        })
      });
    })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  componentDidMount() {
    this.unsubscribe = CarDataService.getAll().orderBy("name", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleAddCarClick() {
    this.setState({ showCar: true });
    this.props.history.push('/addcar');
  }

  onDataChange(items) {
    let cars = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();

      if (typeof data.uuidDrivers !== 'undefined' || data.uuidDrivers != null) {
        var seleted_drivers = data.uuidDrivers.map(id => {
          return this.drivers.find(
            driver => driver.id === id
          )
        })
      };

      cars.push({
        id: id,
        brand: data.brand,
        car_group: data.car_group,
        car_type: data.car_type,
        description: data.description,
        image_path: data.image_path,
        min_work_hours: data.min_work_hours,
        healthy: data.healthy,
        model: data.model,
        default_driver: data.default_driver,
        name: data.name,
        plate_number: data.plate_number,
        uuidDrivers: data.uuidDrivers,
        seleted_drivers: seleted_drivers,
        published: data.published,
      });
    });

    this.setState({
      cars: cars,
    });

  }

  refreshList() {
    this.setState({
      currentCar: null,
      currentIndex: -1,
    });
  }

  setActiveCar(car, index) {
    this.setState({
      currentCar: car,
      currentIndex: index,
    });
  }

  imageClick(car_group) {
    this.setState({
      CarGroupStatus: car_group
    })
  }

  render() {

    const { cars, currentCar, CarGroupStatus, currentIndex } = this.state;

    const cardTextStyle = {
      "marginBottom": 0,
    }

    return (
      <div>
        <h5>Список Техники</h5>
        <Row className="row justify-content-left">
          <button style={{ border: this.state.CarGroupStatus === "lift_truck" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/lift_truck.svg" height="65px" alt="lift_truck" onClick={() => this.imageClick("lift_truck")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "eks_pogruzhik_kolesniy" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/eks_pogruzhik_kolesniy.svg" height="65px" alt="eks_pogruzhik_kolesniy" onClick={() => this.imageClick("eks_pogruzhik_kolesniy")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "perevozka_light" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/perevozka_light.svg" height="65px" alt="perevozka_light" onClick={() => this.imageClick("perevozka_light")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "pogruzhik" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/pogruzhik.svg" height="65px" alt="pogruzhik" onClick={() => this.imageClick("pogruzhik")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "road_tools" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/road_tools.svg" height="65px" alt="road_tools" onClick={() => this.imageClick("road_tools")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "eks_povorotniy" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/eks_povorotniy.svg" height="65px" alt="eks_povorotniy" onClick={() => this.imageClick("eks_povorotniy")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "perevozka_10_20_30t" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/perevozka_10_20_30t.svg" height="65px" alt="perevozka_10_20_30t" onClick={() => this.imageClick("perevozka_10_20_30t")} /></button>
          <button style={{ border: this.state.CarGroupStatus === "all_trucks" ? "3px solid black" : "none" }}><img src="https://storage.googleapis.com/dzetruck-cars/WEB/icons/all_trucks.svg" height="65px" alt="all_trucks" onClick={() => this.imageClick("all_trucks")} /></button>
        </Row>
        <div className="list row">
          <Col>
            <br></br>
            <Row>
              <Col align="right">
                <Button
                  color="warning"
                  onClick={this.handleAddCarClick}
                  className="btn btn-primary btn-sm">Добавить
          </Button>
              </Col>
            </Row>
            <br></br>
            <div className="scroller">
              {cars &&
                (CarGroupStatus === 'all_trucks' ? cars : cars.filter(e => e.car_group === CarGroupStatus))
                  .map((car, index) => (
                    <Card style={{ "marginBottom": "25px", "padding": 0 }}
                      key={index}
                      className={"list-group-item " + (index === currentIndex ? "list-group-item-action list-group-item-warning" : "")}
                      onClick={() => this.setActiveCar(car, index)}
                    >
                      <Card.Header
                        style={{
                          'backgroundColor': '#CC9933',
                          padding: '3px',
                          color: 'white',
                        }}>
                        <Row>
                          <Col style={{ "textAlign": "right" }}> Индефикатор:{car.id}  </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body style={{ padding: 0 }}>
                        <Container style={{ "margin": "0px" }}>
                          <Row >
                            <Col style={{ "paddingLeft": 0 }}>
                              <Card.Img style={{ margin: 0 }} src={car.image_path} />
                            </Col>
                            <Col align="left">
                              <Card.Text style={{ fontWeight: 'bold', "marginTop": "0px", "marginBottom": "3px" }} align="left">основной водитель:</Card.Text>
                              <Card.Text style={cardTextStyle}>{car.default_driver}</Card.Text>
                              <Card.Text style={{ fontWeight: 'bold', "marginTop": "0px", "marginBottom": "3px" }} align="left">дополнительные водители:</Card.Text>
                              <Card.Text style={cardTextStyle}>{car.uuidDrivers && car.uuidDrivers.join(', ')}</Card.Text>
                              <Card.Text style={{
                                "fontWeight": 'bold',
                                "marginTop": "5px",
                                "marginBottom": "3px"
                              }} align="left">описание:</Card.Text>
                              <Card.Text style={cardTextStyle}>модель:{car.model}</Card.Text>
                              <Card.Text style={cardTextStyle}>бренд:{car.brand}</Card.Text>
                              <Card.Text style={cardTextStyle}>тип:{car.car_type}</Card.Text>
                              <Card.Text style={cardTextStyle}>номерной знак:{car.plate_number}</Card.Text>
                              <Card.Text style={cardTextStyle}>мин количество часов:{car.min_work_hours}</Card.Text>
                            </Col>
                            <Col md="auto">
                              {car.healthy === true ? <div style={{ color: 'green', fontWeight: 'medium' }}>ИСПРАВНА <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                              </svg></div> : <div style={{ color: 'red', fontWeight: 'medium' }}>НЕ ИСПРАВНА <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg></div>}
                            </Col>
                          </Row>
                        </Container>
                      </Card.Body>
                    </Card>
                  ))}
            </div>
          </Col>
          <Col>
            {currentCar ? (
              <Car
                car={currentCar}
                refreshList={this.refreshList}
              />
            ) : (
              <div>
                <br />
                <p>Выберите машину.......</p>
              </div>
            )}
          </Col>
        </div>
      </div >
    );
  }
}
