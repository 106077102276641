import React, { Component } from "react";
import OrderDataService from "../services/firebase_orders.service";
import CarDataService from "../services/firebase.service";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory, { dateFilter, Comparator } from 'react-bootstrap-table2-filter';


const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

export default class ListTodaysOrders extends Component {
    constructor(props) {
        super(props);
        this.onDataChange = this.onDataChange.bind(this);

        this.state = {
            orders: []
        }

        this.unsubscribe = undefined;
    }

    componentDidMount() {
        console.log('Did Mount');
        this.unsubscribe = OrderDataService.getAll().orderBy("requestedDateTime", "asc").onSnapshot(this.onDataChange);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let orders = [];

        items.forEach((item) => {
            let id = item.id;
            let data = item.data();

            var date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var time_options = { hour: '2-digit', minute: '2-digit' };

            const timestamp = data.requestedDateTime.toDate();

            orders.push({
                id: id, // id of the order/ticket
                name: data.name,
                address: data.address,
                comments: data.comments,
                mobile: data.mobile,
                driverUuid: data.driverUuid,
                createdByName: data.createdByName,
                approvedByName: data.approvedByName,
                completedBy: data.completedBy,
                completedByName: data.completedByName,
                startTime: data.startTime,
                endTime: data.endTime,
                time: timestamp.toLocaleTimeString([], time_options), // 15:00
                timeWork: data.timeWork,
                ticketNumber: data.ticketNumber,
                requestedDateTime: data.requestedDateTime.toDate().toString(),
                date: timestamp.toLocaleDateString("ru-Ru", date_options),
                carUuid: data.carUuid,
                timestamp: timestamp,
                status: data.status,
            });
        });

        this.setState({
            orders: orders,
        });

        items.forEach((item) => {
            let id = item.id;
            let data = item.data();

            if (data.carUuid !== null)
            {

            CarDataService.get_data(data.carUuid).then((doc) => {
                const carGroup = doc.data().car_group;
                const imagePath = doc.data().image_path;
                const Name = doc.data().name;
                const order = orders.filter(x => x.id === item.id);

                order.carGroup = carGroup;
                order.imagePath = imagePath;
                order.Name = Name;
                order.id = item.id;

                //Find index of specific object using findIndex method.
                const objIndex = orders.findIndex((obj => obj.id === id));

                //Update object's image_path property.
                orders[objIndex].image_path = order.imagePath
                orders[objIndex].name = order.Name
                orders[objIndex].car_group = order.carGroup

                order.imageIsLoaded = true;
                const notLoadedOrder = orders.filter(x => !x.imageIsLoaded);

                this.setState({
                    orders: orders
                });

                if (!notLoadedOrder) {
                    this.setState({
                        carsReady: true
                    });
                };
            });
        } else 
        console.log("conflict order",id)
        });
    }

    render() {

        const { orders } = this.state;

        const columns = [{
            dataField: 'name',
            text: 'Название',
            sort: true
        },
        {
            dataField: 'createdByName',
            text: 'Заказ создал',
            sort: true
        },
        {
            dataField: 'approvedByName',
            text: 'Заказ подтвердил',
            sort: true
        },
        {
            dataField: 'driverUuid',
            text: 'Водитель',
            sort: true
        },
        {
            dataField: 'completedByName',
            text: 'Заказ выполнил',
            sort: true
        },
        {
            dataField: 'timestamp',
            text: 'Дата подачи',
            formatter: cell => { return cell.toLocaleString(); },
            sort: true,
            filter: dateFilter({
                defaultValue: { date: new Date(), comparator: Comparator.GT }
            })
        },
        {
            dataField: 'timeWork',
            text: 'Планируемое время работы (ч)',
            sort: true
        },
        {
            dataField: 'address',
            text: 'Адрес',
            sort: false
        },
        {
            dataField: 'mobile',
            text: 'Контактный телефон',
            sort: false
        },
        {
            dataField: 'status',
            text: 'статус',
            sort: true,
            style: (cell) => {
                if (cell === "done" || cell === "approved") {
                    return {
                        backgroundColor: '#81c784'
                    };
                } else if (cell === "created" || cell === "in_progress") {
                    return {
                        backgroundColor: '#f2e550'
                    };
                } else if (cell === "rejected") {
                    return {
                        backgroundColor: '#e63b35'
                    };
                } else
                    return {
                        backgroundColor: '#f2e550'
                    };
            }
        },
        ];

        const defaultSorted = [{
            dataField: 'timestamp',
            order: 'asc'
        }];

        const pagination = paginationFactory({
            sizePerPage: 20
        });

        return (
            <div>
                <ToolkitProvider
                    bootstrap4
                    keyField="id"
                    search
                    data={orders}
                    columns={columns}
                    exportCSV={{
                        onlyExportFiltered: true,
                        exportAll: false,
                        ignoreHeader: false,
                        // separator: "\t",
                        // blobType: "text/csv;charset=ansi",
                        fileName: "dzetruck.csv",
                        // noAutoBOM: false
                    }}
                // exportCSV
                >
                    {
                        props => (
                            <div>
                                <SearchBar
                                    searchText="Поиск"
                                    placeholder="Поиск"
                                    {...props.searchProps} />
                                <ClearSearchButton
                                    text="сбросить поиск"
                                    {...props.searchProps} />
                                <hr />
                                <BootstrapTable
                                    defaultSorted={defaultSorted}
                                    striped
                                    hover
                                    condensed
                                    pagination={pagination}
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                />
                                <ExportCSVButton
                                    {...props.csvProps}>Экспортировать в CSV
                                 </ExportCSVButton>
                            </div>
                        )
                    }
                </ToolkitProvider>

            </div >
        )
    }
}
