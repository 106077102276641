import {
    CheckCircle,
    Truck,
    Target,
    Clock
} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Список техники', icon: Truck, type: 'link', path: '/listcars', active: true
    },
    {
        title: 'Список заказов', icon: Target, type: 'link', path: '/listorders', active: false
    },
    {
        title: 'Список пользователей', icon: Target, type: 'link', path: '/listusers', active: false
    },
    {
        title: 'Список техники(для механика)', icon: CheckCircle, type: 'link', path: '/listcarscards', active: false
    },
    {
        title: 'Список заказов(для менеджера)', icon: CheckCircle, type: 'link', path: '/listorderscards', active: false
    },
    {
        title: 'Список заказов график', icon: Clock, type: 'link', path: '/gantt-chart', active: false
    },
    {
        title: 'Список заказов на сегодня', icon: Target, type: 'link', path: '/list_today_orders', active: true
    },
       {
        title: 'Графики', icon: Target, type: 'link', path: '/charts/googleChart', active: false
    },

]
