import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { createMuiTheme } from '@material-ui/core/styles';


import db from '../data/config';
import "./scroller.css";

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#00cc36',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#cf000f',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#cf000f',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        maxWidth: 345,
        // maxHeight: 450,
        // minHeight: 300,
    },
    media: { height: 140, }
}));


export default function NestedGrid() {

    const [orderList, setOrderList] = React.useState([]);

    useEffect(() => {
        const subscriber = db
            .collection('tickets')
            .where('status', '==', 'created')
            .onSnapshot(querySnapshot => {
                const orderList = [];
                querySnapshot.forEach(doc => {
                    orderList.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });

                setOrderList(orderList);

            });

        // Unsubscribe from events when no longer in use
        return () => subscriber();
    }, []);


    const handleOnClick = status_object => e => {

        const status = status_object["status"]

        if (status === "approve") {
            UpdateOrder(status_object);
            console.log("approving this order")
        } else if (status === "reject") {
            RejectOrder(status_object)
            console.log("rejecing this order")
        } else {
            console.log("unknown status")
        }
    };

    function UpdateOrder(status_object) {

        const order_id = status_object["id"]
        const index = orderList.findIndex(element => element.id === order_id)

        let newarr = orderList[index] = {
            ...orderList[index],
            status: 'approved',
            approvedDate: new Date(),
            approvedByName: 'менеджер (через сайт)',
        }
        console.log(newarr);
        db.collection('tickets').doc(order_id).update(newarr);
    }

    function RejectOrder(status_object) {

        const order_id = status_object["id"]
        const index = orderList.findIndex(element => element.id === order_id)

        let newarr = orderList[index] = {
            ...orderList[index],
            status: 'rejected',
            approvedDate: new Date(),
            rejectedByName: 'менеджер (через сайт)',
        }
        console.log(newarr);
        db.collection('tickets').doc(order_id).update(newarr);
    }

    function CarCard({ id, createdByName, driverUuid, address, comments, timeWork, requestedDateTime, carImagePath, ticketNumber }) {
        const classes = useStyles();

        const timestamp = new Date(requestedDateTime);

        var time_options = { hour: '2-digit', minute: '2-digit' };
        var date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        const time = timestamp.toLocaleTimeString([], time_options)
        const date = timestamp.toLocaleDateString("ru-Ru", date_options)

        var dt = new Date(requestedDateTime);
        dt.setHours(dt.getHours() + timeWork);

        const bookedUntilTime = dt.toLocaleTimeString([], time_options)
        const bookedUntilDate = dt.toLocaleDateString("ru-Ru", date_options)

        return (
            <Card className={classes.card} >
                <CardHeader
                    subheader={`заказ: ${ticketNumber}`}
                >
                </CardHeader>
                <CardMedia className={classes.media} image={carImagePath} />
                <CardContent>
                    <Typography variant="body1" color="textPrimary" component="h5">Подача к: {time}</Typography>
                    <Typography variant="body1" color="textPrimary" component="h5" paragraph={true}>{date}</Typography>
                    <Typography variant="body1" color="textPrimary" component="p">водитель: {driverUuid}</Typography>
                    <Typography variant="body1" color="textPrimary" component="p">заказчик: {createdByName}</Typography>
                    <Typography variant="body1" color="textSecondary" component="p">адрес: {address}</Typography>
                    <Typography variant="body1" color="textSecondary" component="p">планирумое время работы: {timeWork}</Typography>
                    <Typography variant="body1" color="textSecondary" component="p" paragraph={true}>комментарии {comments}</Typography>
                    <Typography variant="body1" color="textPrimary" component="p">забронировано до {bookedUntilTime}</Typography>
                    <Typography variant="body1" color="textPrimary" component="p">{bookedUntilDate}</Typography>
                </CardContent>
                <CardActions style={{ 'justifyContent': 'space-evenly' }}>
                    {/* <Button style={{ 'color': 'green' }}
                        key={id}
                        size="small"
                        color="primary"
                        onClick={handleOnClick({
                            "status": "approve",
                            "id": id
                        })}
                    >
                        ОДОБРИТЬ
        </Button> */}
                    <Button style={{ 'color': 'red' }}
                        key={`${id}` + "reject"}
                        size="small"
                        color="primary"
                        onClick={handleOnClick({
                            "status": "reject",
                            "id": id
                        })}
                    >
                        ОТКЛОНИТЬ
        </Button>
                </CardActions>
            </Card >);
    }

    return (
        <div>
            <h5>Количество непроверенных заказов {orderList.length}</h5>
            <Grid
                container spacing={5}
                style={{ padding: '10px' }}
            >
                {orderList && orderList
                    .filter(e => e.status === "created")
                    .map((s, index) =>
                        < Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={3} >
                            <CarCard
                                id={s.id}
                                key={s.id}
                                createdByName={s.createdByName}
                                address={s.address}
                                driverUuid={s.driverUuid}
                                comments={s.comments}
                                car_group={s.car_group}
                                car_type={s.car_type}
                                timeWork={s.timeWork}
                                ticketNumber={s.ticketNumber}
                                requestedDateTime={s.requestedDateTime.toDate()}
                                carImagePath={s.carImagePath}
                            />
                        </Grid>)}
            </Grid>
        </div >)
}
