import React from 'react';

export const productColumns = [
      {
        name:"Индефикатор",
        selector: 'id',
        sortable: true,
        center:true,
      },
      {
        name:"Название",
        selector: 'name',
        sortable: true,
        center:true,
      },
      {
        name:"Бренд",
        selector: 'brand',
        sortable: true,
        center:true,
      },
      {
        name:"Группа",
        selector: 'car_group',
        sortable: true,
        center:true,
      },
      {
        name:"Тип",
        selector: 'car_type',
        sortable: true,
        center:true,
      },
      {
        name:"Мин продолжительность",
        selector: 'min_work_hours',
        sortable: true,
        center:true,
      },
      {
        name:"Модель",
        selector: 'model',
        sortable: true,
        center:true,
      },
      {
        name:"Номерноый знак",
        selector: 'plate_number',
        sortable: true,
        center:true,
      },
      {
        name:"Водитель",
        selector: 'uuidDrivers',
        sortable: true,
        center: true,
      },
      {
        name:"Рабочая",
        selector: 'healthy',
        cell: row =>  <div>{row.healthy === true ? "Рабочая" : "Не рабочая"}</div>, //{row.healthy == true ? "Рабочая" : "Не рабочая"}
        sortable: true,
        center:true,
      },
      {
        name: 'Фото',
        selector: 'image_path',
        cell: row => <img src={row.image_path} style={{ width: 200, height: 150 }} alt="" />,
        sortable: true,
        center:true,
      },
];
