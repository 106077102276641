import React, { Component } from "react";
import CarDataService from "../../services/firebase.service";
import UserDataService from "../../services/firebase_user.service";
import { Label, Input, Button } from 'reactstrap'
import { Row, Col } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';

export default class Car extends Component {
  constructor(props) {
    super(props);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeImagePath = this.onChangeImagePath.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangePlateNumber = this.onChangePlateNumber.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeCarGroup = this.onChangeCarGroup.bind(this);
    this.onChangeCarType = this.onChangeCarType.bind(this);
    this.onChangeMinWorkHours = this.onChangeMinWorkHours.bind(this);
    this.onChangeModel = this.onChangeModel.bind(this);
    this.onChangeDefaultDriver = this.onChangeDefaultDriver.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateCar = this.updateCar.bind(this);
    this.deleteCar = this.deleteCar.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);

    this.state = {
      currentCar: {
        id: null,
        name: "",
        image_path: "",
        description: "",
        brand: "",
        car_group: "",
        car_type: "",
        min_work_hours: 4,
        healthy: "",
        model: "",
        default_driver: "",
        plate_number: "",
        uuidDrivers: [],
        published: false,
      },
      selectedValues: [],
      message: "",
      imagePathIsValidated: false,
      errorsName: "",
      errorsImagePath: ""
    };

    this.unsubscribe = undefined;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { car } = nextProps;
    if (prevState.currentCar.id !== car.id) {
      return {
        currentCar: car,
        message: ""
      };
    }

    return prevState.currentCar;
  }

  componentDidMount() {
    this.setState({
      currentCar: this.props.car,
    });

    this.unsubscribe = UserDataService.get_all_by_role("driver").orderBy("username", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items) {
    let all_drivers = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();
      all_drivers.push({
        id: id,
        username: data.username,
      });
    });

    this.setState({
      all_drivers: all_drivers,
    });

  }

  onChangeName(e) {
    const name = e.target.value;

    this.setState(function (prevState) {
      return {
        currentCar: {
          ...prevState.currentCar,
          name: name,
        },
      };
    });
  }

  onChangeImagePath(e) {
    const image_path = e.target.value;

    if (typeof image_path != 'undefined' && image_path.length > 1) {
      this.setState(
        {
          imagePathIsValidated: true,
          errorsImagePath: ""
        }
      );
    } else {
      this.setState(
        {
          imagePathIsValidated: false,
          errorsImagePath: "вставте ссылку на картинку"
        }
      );
    }

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        image_path: image_path,
      },
    }));
  }

  onChangeDescription(e) {
    const description = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        description: description,
      },
    }));
  }

  onChangeBrand(e) {
    const brand = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        brand: brand,
      },
    }));
  }

  onChangeCarGroup(e) {
    const car_group = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        car_group: car_group,
      },
    }));
  }

  onChangeCarType(e) {
    const car_type = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        car_type: car_type,
      },
    }));
  }

  onChangeMinWorkHours(e) {
    const min_work_hours = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        min_work_hours: Number(min_work_hours),
      },
    }));
  }

  onChangeModel(e) {
    const model = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        model: model,
      },
    }));
  }

  onChangeDefaultDriver(e) {
    const default_driver = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        default_driver: default_driver,
      },
    }));
  }

  onChangePlateNumber(e) {
    const plate_number = e.target.value;

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        plate_number: plate_number,
      },
    }));
  }

  updatePublished(status) {
    CarDataService.update(this.state.currentCar.id, {
      published: status,
    })
      .then(() => {
        this.setState((prevState) => ({
          currentCar: {
            ...prevState.currentCar,
            published: status,
          },
          message: "Статус был обновлен",
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateCar() {

    var driver_ids = this.state.currentCar.seleted_drivers.map(function (item) {
      return item['id'];
    });

    const data = {
      name: this.state.currentCar.name,
      image_path: this.state.currentCar.image_path,
      description: this.state.currentCar.description,
      brand: this.state.currentCar.brand,
      car_group: this.state.currentCar.car_group,
      car_type: this.state.currentCar.car_type,
      min_work_hours: this.state.currentCar.min_work_hours,
      healthy: this.state.currentCar.healthy,
      model: this.state.currentCar.model,
      plate_number: this.state.currentCar.plate_number,
      uuidDrivers: driver_ids,
      default_driver: this.state.currentCar.default_driver,
    };

    CarDataService.update(this.state.currentCar.id, data)
      .then(() => {
        this.setState({
          message: "Техника обновлена",
        });
        setTimeout(() => { this.setState({ message: "" }); }, 2500);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteCar() {
    CarDataService.delete(this.state.currentCar.id)
      .then(() => {
        this.props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // Drivers Multi Select
  onSelect(selectedList, selectedItem) {

    const selected_list = selectedList;

    this.setState(function (prevState) {
      return {
        currentCar: {
          ...prevState.currentCar,
          seleted_drivers: selected_list,
        },
      };
    });

  }

  onRemove(selectedList, removedItem) {

    this.setState((prevState) => ({
      currentCar: {
        ...prevState.currentCar,
        seleted_drivers: selectedList,
      },
    }));
  }

  render() {
    const { all_drivers, currentCar } = this.state;

    return (
      <div>
        <br></br>
        <h5>Уникальный индефикатор техники: {currentCar.id}</h5>
        <Col style={{ "lineHeight": "0.5" }}>
          <Row>
            <img src={currentCar.image_path} alt={currentCar.id} width="300" height="250" style={{ "margin": "10px" }} />
          </Row>
          {currentCar ? (
            <div className="edit-form">
              <form>
                <div className="form-group">
                  <label htmlFor="name">название</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={currentCar.name}
                    onChange={this.onChangeName}
                  />
                  <div></div>
                </div>
                <div className="form-group">
                  <label htmlFor="image_path">Ссылка на фото</label>
                  <input
                    type="text"
                    className="form-control"
                    id="image_path"
                    value={currentCar.image_path}
                    onChange={this.onChangeImagePath}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">описание</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    value={currentCar.description}
                    onChange={this.onChangeDescription}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="brand">Бренд</label>
                  <input
                    type="text"
                    className="form-control"
                    id="brand"
                    value={currentCar.brand}
                    onChange={this.onChangeBrand}
                  />
                </div>
                <div className="form-group">
                  <Label for="car_group">Группа техники</Label>
                  <Input
                    type="select"
                    name="select"
                    id="car_group"
                    value={currentCar.car_group}
                    defaultValue="eks_pogruzhik_kolesniy"
                    onChange={this.onChangeCarGroup}>
                    <option>eks_pogruzhik_kolesniy</option>
                    <option>eks_povorotniy</option>
                    <option>lift_truck</option>
                    <option>perevozka_10_20_30t</option>
                    <option>perevozka_light</option>
                    <option>pogruzchik</option>
                    <option>road_tools</option>
                  </Input>
                </div>
                <div className="form-group">
                  <Label for="car_type">Тип техники</Label>
                  <Input
                    type="select"
                    name="select"
                    id="car_type"
                    defaultValue="avto-kran"
                    value={currentCar.car_type}
                    onChange={this.onChangeCarType}>
                    <option>avto-kran</option>
                    <option>avtograyder</option>
                    <option>betonodrobilka</option>
                    <option>drobilka</option>
                    <option>excavator</option>
                    <option>excavator-pogruzchik</option>
                    <option>katok</option>
                    <option>kran</option>
                    <option>perevozchik</option>
                    <option>samosval</option>
                  </Input>
                </div>
                <div className="form-group">
                  <label htmlFor="min_work_hours">Минимальное количество часов для заказа</label>
                  <input
                    type="number"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    placeholder="Введите минимальное количесво часов для техники"
                    className="form-control"
                    id="min_work_hours"
                    minLength={1}
                    maxLength={4}
                    value={currentCar.min_work_hours}
                    onChange={this.onChangeMinWorkHours}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="model">Модель</label>
                  <input
                    type="text"
                    className="form-control"
                    id="model"
                    value={currentCar.model}
                    onChange={this.onChangeModel}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="default_driver">Основной водитель</label>
                  <Input
                    type="select"
                    name="select"
                    className="form-control"
                    id="default_driver"
                    value={currentCar.default_driver || ""}
                    onChange={this.onChangeDefaultDriver}>
                    {all_drivers && all_drivers.map((e, key) => {
                      return <option key={key} value={e.id}>{e.username}</option>;
                    })}
                  </Input>
                </div>
                <label>Дополнительные водители</label>
                <Multiselect
                  options={all_drivers} // Options to display in the dropdown
                  selectedValues={this.props.car.seleted_drivers} //  ["DRIVER1","DRIVER2"] ==> ""
                  onSelect={this.onSelect} // Function will trigger on select event
                  onRemove={this.onRemove} // Function will trigger on remove event
                  displayValue="username" // Property name to display in the dropdown options
                  placeholder="Поиск"
                />
                <div className="form-group">
                  <label htmlFor="plate_number">Номерной знак</label>
                  <input
                    type="text"
                    className="form-control"
                    id="plate_number"
                    value={currentCar.plate_number}
                    onChange={this.onChangePlateNumber}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <strong>Статус:</strong>
                  </label>
                  {currentCar.healthy === true ? "Рабочая" : "Не рабочая"}
                </div>
              </form>
              <Button
                color="danger" className="btn-sm"
                onClick={(e) => { if (window.confirm('Вы точно хотите уделить эту технику')) this.deleteCar(e) }}
              >
                Удалить
                  </Button>
              {" "}
              <Button
                color="secondary" className="btn-sm"
                type="submit"
                onClick={this.updateCar}
              >
                Применить
                  </Button>
              {" "}
              <p>{this.state.message}</p>
            </div>
          ) : (
            <div>
              <br />
              <p>Выберите машину...</p>
            </div>
          )}
        </Col>
      </div>
    );
  }
}
