import firebase from "../data/config";

const db = firebase.collection("/tickets");

class DataService {

  getByStatus(status) {
    let result;
    if (status === "all") {
      result = db;
    } else {
      result = db.where('status', '==', status)
    }
    return result;
  }

  getActive() {
    return db.where('status', 'in', ['approved', 'in_progress', "created"])
  }

  getAll() {
    return db
  }

  create(order) {
    return db.add(order);
  }

  update(id, value) {
    return db.doc(id).update(value);
  }

  delete(id) {
    return db.doc(id).delete();
  }
}

export default new DataService();
