import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

// ListCars
import ListCars from './pages/ListCars';
import ListOrders from './pages/ListOrders';
import ListTodaysOrders from './pages/ListTodaysOrders';
import ListUsers from './pages/ListUsers';

import ListCarsCards from './pages/ListCarsCards';
import ListOrdersCards from './pages/ListOrdersCards';

// import KanbanBoard from './components/applications/kanban-board/kanbanBoard'

import GanttChart from './pages/GanttChart';

import CarList2 from './components/applications/ecommerce-app/car-list';

import AddCar from './components/car/add_car';

import AddUser from './components/user/add_user';

// charts
import GoogleChart from './components/charts/googleChart';

//login
import { useState } from 'react';
import Signin from './auth/signin';
import Login from './pages/login';
// import Callback from './auth/callback'

// users
import UserProfile from './components/users/userProfile';
import UserEdit from './components/users/userEdit';
import UserCards from './components/users/user-cards';

//config data
import configDB from './data/customizer/config'
import { firebase_app } from './data/config';

import Error400 from './pages/errors/error400';
import Error401 from './pages/errors/error401';
import Error403 from './pages/errors/error403';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Error503 from './pages/errors/error503'

//firebase Auth
function Root() {
    // login
    //const abortController = new AbortController();
    const AbortController = window.AbortController;
    const [currentUser, setCurrentUser] = useState(false);
    const [currentUserEmail, setCurrentUserEmail] = useState("");
    const [authenticated, setAuthenticated] = useState(false);

    firebase_app.auth().onAuthStateChanged(function (user) {
        if (user) {
            setCurrentUserEmail(user.email);
        } else {
            setCurrentUserEmail("");
        }
    });

    useEffect(() => {
        // const requestOptions = { method: 'GET', headers: authHeader() };
        // fetch('/users', requestOptions).then(handleResponse)
        const color = localStorage.getItem('color')
        const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
        firebase_app.auth().onAuthStateChanged(setCurrentUser);

        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        document.body.classList.add(layout);
        console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);

        return function cleanup() {
            const controller = new AbortController()
            controller.abort();
        }

        // eslint-disable-next-line
    }, []);
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500} />
                            <Route path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503} />
                            {/* <Route  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} /> */}
                            {currentUser !== null || authenticated ?
                                <App>
                                    {/* dashboard menu */}
                                    <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                        return (<Redirect to={`${process.env.PUBLIC_URL}/listorders`} />)
                                    }} />

                                    <Route path={`${process.env.PUBLIC_URL}/listcars2`} component={CarList2} />
                                    <Route path={`${process.env.PUBLIC_URL}/addcar`} component={AddCar} />
                                    <Route path={`${process.env.PUBLIC_URL}/adduser`} component={AddUser} />

                                    {/* ListCars */}
                                    <Route path={`${process.env.PUBLIC_URL}/listcars`} component={ListCars} />
                                    {/* ListOrders */}
                                    <Route path={`${process.env.PUBLIC_URL}/listorders`} component={ListOrders} />
                                    <Route path={`${process.env.PUBLIC_URL}/list_today_orders`} component={ListTodaysOrders} />
                                    <Route path={`${process.env.PUBLIC_URL}/listusers`} component={ListUsers} />
                                    <Route path={`${process.env.PUBLIC_URL}/listcarscards`} component={ListCarsCards} />
                                    <Route path={`${process.env.PUBLIC_URL}/listorderscards`} component={ListOrdersCards} />
                                    <Route path={`${process.env.PUBLIC_URL}/gantt-chart`} component={GanttChart} />
                                    {/* <Route path={`${process.env.PUBLIC_URL}/kanban-board`} component={KanbanBoard} /> */}
                                    {/* Charts */}
                                    <Route path={`${process.env.PUBLIC_URL}/charts/googleChart`} component={GoogleChart} />
                                    {/* Users */}
                                    <Route path={`${process.env.PUBLIC_URL}/users/userProfile`} component={UserProfile} />
                                    <Route path={`${process.env.PUBLIC_URL}/users/userEdit`} component={UserEdit} />
                                    <Route path={`${process.env.PUBLIC_URL}/users/userCards`} component={UserCards} />

                                </App>
                                :
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            }
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();
