import React, { useEffect, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Chart } from "react-google-charts";

import db from "../../data/config";

const GoogleChart = () => {
  const [orderList, setOrderList] = React.useState([]);
  const [carList, setCarList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);

  //Get today's date using the JavaScript Date object.
  var today = new Date();
  var month_ago = new Date();

  //Change it so that it is 30 days in the past.
  var pastDate = today.getDate() - 30;
  month_ago.setDate(pastDate);

  useEffect(() => {
    const subscriber = db
      .collection("tickets")
      .where("createdDate", "<=", today)
      .where("createdDate", ">=", month_ago)
      .orderBy("createdDate", "asc")
      .onSnapshot((querySnapshot) => {
        const orderList = [];
        querySnapshot.forEach((doc) => {
          orderList.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        setOrderList(orderList);
      });

    db.collection("cars").onSnapshot((querySnapshot) => {
      const carList = [];
      querySnapshot.forEach((doc) => {
        carList.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setCarList(carList);
    });

    db.collection("user").onSnapshot((querySnapshot) => {
      const userList = [];
      querySnapshot.forEach((doc) => {
        userList.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setUserList(userList);
    });

    // Unsubscribe from events when no longer in use
    return () => subscriber();
  }, []);

  // tickets
  var created_tickets_count = orderList.filter(
    (e) => e.status === "created"
  ).length;
  var approved_tickets_count = orderList.filter(
    (e) => e.status === "approved"
  ).length;
  var rejected_tickets_count = orderList.filter(
    (e) => e.status === "rejected"
  ).length;
  var in_progress_tickets_count = orderList.filter(
    (e) => e.status === "in_progress"
  ).length;
  var done_tickets_count = orderList.filter((e) => e.status === "done").length;

  let created_tickets_days = [];

  orderList.forEach((ticket) => {
    created_tickets_days.push([ticket["createdDate"].toDate()]);
  });

  var order_per_day_arr = [];

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  created_tickets_days.forEach((day) => {
    let orders_per_day = created_tickets_days.filter((el) =>
      datesAreOnSameDay(el[0], day[0])
    ).length;

    // set date to 00:00
    let new_date = day[0].setHours(0, 0, 0, 0);
    order_per_day_arr.push([new_date, orders_per_day]);
  });

  // remove duplicate date from array
  // let liner_chart_data = order_per_day_arr
  let liner_chart_data = Array.from(
    new Set(order_per_day_arr.map(JSON.stringify)),
    JSON.parse
  );

  let LineChartArr = [];

  liner_chart_data.forEach((x) => {
    var date = new Date(x[0]);
    LineChartArr.push([date, x[1]]);
  });

  LineChartArr.unshift([
    { type: 'date', label: 'Start Date (Short)' },
    ''
  ]
    );

  // cars
  var healhy_cars_count = carList.filter((e) => e.healthy === true).length;
  var unhealhy_cars_count = carList.filter((e) => e.healthy === false).length;
  var unknown_health_cars_count =
    carList.length - healhy_cars_count - unhealhy_cars_count;

  // users
  // list users
  var drivers_list = userList.filter((e) => e.roles === "driver");

  let driver_utiliziation = [];

  drivers_list.forEach((driver) => {
    driver_utiliziation.push([driver["username"]]);
  });

  driver_utiliziation.forEach((arr) => {
    let completed_tickets = orderList.filter(
      (e) => e.completedByName === arr[0]
    ).length;
    arr.push(completed_tickets);
  });

  driver_utiliziation.unshift(["Водители", "Кол-во выполненых заказов"]);

  // client user list

  var clients_list = userList.filter((e) => e.roles === "client");
  let client_utiliziation = [];

  clients_list.forEach((client) => {
    client_utiliziation.push([client["username"]]);
  });

  client_utiliziation.forEach((arr) => {
    let created_tickets_by_client_count = orderList.filter(
      (e) => e.createdByName === arr[0]
    ).length;
    arr.push(created_tickets_by_client_count);
  });

  client_utiliziation.unshift(["Клиенты", "Кол-во заказов"]);

  // tickets
  // completedByName
  // status = completed

  return (
    <Fragment>
      <Breadcrumb title="Обзор графиков" parent="Chart" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Распределение заказов за последние 30 дней</h5>
              </div>
              <div className="card-body">
                <Chart
                  width={"100%"}
                  height={"400px"}
                  chartType="PieChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={[
                    ["Task", "Hours per Day"],
                    ["Созданые/Новые", created_tickets_count],
                    ["Отклоненные", rejected_tickets_count],
                    ["Одобренные", approved_tickets_count],
                    ["в процессе", in_progress_tickets_count], //in_progress
                    ["Выполненые", done_tickets_count], //done
                  ]}
                  options={{
                    // title: 'Статус заказов',
                    colors: [
                      "#fa9f40",
                      "#f85370",
                      "#1b7aff",
                      "#22af47",
                      "#22af47",
                    ],
                    // Just add this option
                    pieHole: 0.4,
                  }}
                  rootProps={{ "data-testid": "1" }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Состояние техники</h5>
              </div>
              <div className="card-body">
                <Chart
                  width={"100%"}
                  height={"400px"}
                  chartType="PieChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={[
                    ["Task", "Hours per Day"],
                    ["Исправная", healhy_cars_count],
                    ["На ремонте", unhealhy_cars_count],
                    ["Не найдено", unknown_health_cars_count],
                  ]}
                  options={{
                    title: "",
                    colors: ["#4466f2", "#f85370", "#22af47"],
                    // Just add this option
                    pieHole: 0.4,
                  }}
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Количество выполненых заказов(сортировка по водителю)</h5>
              </div>
              <div className="card-body">
                <Chart
                  //  width={"90%"}
                  //  height={"1200px"}
                  chartType="Table"
                  loader={<div>Loading Chart</div>}
                  data={driver_utiliziation}
                  formatters={[
                    {
                      type: "BarFormat",
                      column: 1,
                      options: {
                        width: 120,
                      },
                    },
                  ]}
                  options={{
                    allowHtml: true,
                    showRowNumber: true,
                    chart: {
                      //   title: "Количество выполненых заказов(сортировка по водителю",
                      subtitle: "данные за последние 30 дней",
                    },
                  }}
                  // For tests
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Количество созданных заказов(сортировка по клиенту)</h5>
              </div>
              <div className="card-body">
                <Chart
                  chartType="Table"
                  loader={<div>Loading Chart</div>}
                  data={client_utiliziation}
                  formatters={[
                    {
                      type: "BarFormat",
                      column: 1,
                      options: {
                        width: 120,
                      },
                    },
                  ]}
                  options={{
                    allowHtml: true,
                    showRowNumber: true,
                    chart: {
                      //   title: "Количество выполненых заказов(сортировка по водителю",
                      subtitle: "данные за последние 30 дней",
                    },
                  }}
                  // For tests
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-xl-6">
            <div className="card">
              <div className="card-header">
                <h5>Количество заказов.Где и на какой стройке(за период)</h5>
              </div>
              <div className="card-body"> */}
          {/* <Chart
                  width={"100%"}
                  height={"400px"}
                  chartType="BarChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={[
                    ["Город", "2020 год", "2019 год"],
                    ["Минск", 543, 321],
                    ["Витебск", 432, 200],
                    ["Гомель", 331, 240],
                    ["Гродно", 201, 320],
                    ["Брест", 180, 99],
                  ]}
                  options={{
                    // title: 'Количество заказов',
                    chartArea: { width: "50%" },
                    colors: ["#4466f2", "#1ea6ec"],
                    hAxis: {
                      title: "Количество заказов",
                      minValue: 0,
                    },
                    vAxis: {
                      title: "Город",
                    },
                  }}
                  // For tests
                  rootProps={{ "data-testid": "1" }}
                /> */}
          {/* </div>
            </div>
          </div> */}
          {/* <div className="col-xl-6">
                        <div className="card">
                            <div className="card-header">
                                <h5>{MaterialDesign}</h5>
                            </div>
                            <div className="card-body">
                                <Chart
                                    width={'100%'}
                                    height={'400px'}
                                    chartType="Bar"
                                    loader={<div>{"Loading Chart"}</div>}
                                    data={[
                                        ['Year', 'Sales', 'Expenses', 'Profit'],
                                        ['2014', 1000, 400, 200],
                                        ['2015', 1170, 460, 250],
                                        ['2016', 660, 1120, 300],
                                        ['2017', 1030, 540, 350],
                                    ]}
                                    options={{
                                        // Material design options
                                        colors: ["#4466f2", "#1ea6ec", "#31af47"],
                                        chart: {
                                            title: 'Company Performance',
                                            subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                                        },
                                    }}
                                    // For tests
                                    rootProps={{ 'data-testid': '2' }}
                                />
                            </div>
                        </div>
                    </div> */}
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Заказы по технике за последние 30 дней</h5>
              </div>
              <div className="card-body">
                <Chart
                    width={'100%'}
                    height={'250%'}
                  chartType="LineChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={LineChartArr}
                  options={{
                    legend: {
                      position: "none"},
                    hAxis: {
                      title: "Time",
                      format: "dd"
                    },
                    vAxis: {
                      title: "Кол-во созданных заказов по дням"
                    }
                  }}
                  rootProps={{ "data-testid": "4" }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Кол-во заказов по месяцам</h5>
              </div>
              <div className="card-body">
                {/* <Chart
                  width={"100%"}
                  height={"300px"}
                  chartType="ComboChart"
                  loader={<div>{"Loading Chart"}</div>}
                  data={[
                    [
                      "excavator",
                      "excavator-pogruzchik",
                      "pogruzchik",
                      "avto-kran",
                      "kran",
                      "perevozchik",
                      "samosval",
                      "katok",
                      "avtograyder",
                    ],
                    ["2020/07", 165, 938, 522, 998, 1200, 614.6, 323, 10],
                    ["2020/08", 135, 1120, 599, 1268, 988, 682, 123, 15],
                    ["2020/09", 157, 1167, 587, 807, 397, 623, 888, 5],
                    ["2020/10", 139, 1110, 615, 968, 815, 609.4, 432, 8],
                    ["2020/11", 136, 691, 629, 777, 1000, 569.6, 676, 20],
                  ]}
                  options={{
                    title: "по типу машин",
                    vAxis: { title: "Заказы" },
                    hAxis: { title: "Месяцы" },
                    seriesType: "bars",
                    colors: [
                      "#4466f2",
                      "#1ea6ec",
                      "#22af47",
                      "#FF5370",
                      "#007bff",
                      "#000000",
                      "#800000",
                    ],
                    // series: { 5: { type: 'line' } },
                  }}
                  rootProps={{ "data-testid": "1" }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GoogleChart;
