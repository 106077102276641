import React, { Component } from "react"
import OrderDataService from "../../services/firebase_orders.service";

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// datepicker
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import addDays from 'date-fns/addDays'
registerLocale("ru", ru);

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeComments = this.onChangeComments.bind(this);
    this.onChangeMinWorkHours = this.onChangeMinWorkHours.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.ApproveOrder = this.ApproveOrder.bind(this);
    this.RejectOrder = this.RejectOrder.bind(this);
    this.deleteOrder = this.deleteOrder.bind(this);
    this.onChangeDateTime = this.onChangeDateTime.bind(this);

    this.state = {
      currentOrder: {
        id: null,
        address: "",
        carUuid: "",
        comments: "",
        driverUuid: "",
        createdBy: "",
        createdDate: "",
        createdByName: "",
        approvedBy: "",
        approvedDate: "",
        approvedByName: "",
        completedBy: "",
        completedByName: "",
        completedDate: "",
        startedBy: "",
        startedDate: "",
        startedByName: "",
        startTime: "",
        endTime: "",
        time: "",
        timeWork: 4,
        requestedDateTime: "",
        date: "",
        status: false,
        image_path: ""
      },
      message: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { order } = nextProps;
    if (prevState.currentOrder.id !== order.id) {
      return {
        currentOrder: order,
        message: ""
      };
    }

    return prevState.currentOrder;
  }

  componentDidMount() {
    this.setState({
      currentOrder: this.props.order,
    });
  }

  onChangeDateTime(d) {

    this.setState(function (prevState) {
      return {
        currentOrder: {
          ...prevState.currentOrder,
          requestedDateTime: d,
        },
      };
    });
  }

  onChangeAddress(e) {
    const address = e.target.value;

    this.setState(function (prevState) {
      return {
        currentOrder: {
          ...prevState.currentOrder,
          address: address,
        },
      };
    });
  }

  onChangeComments(e) {
    const comments = e.target.value;

    this.setState((prevState) => ({
      currentOrder: {
        ...prevState.currentOrder,
        comments: comments,
      },
    }));
  }

  onChangeMinWorkHours(e) {
    const timeWork = e.target.value;

    this.setState((prevState) => ({
      currentOrder: {
        ...prevState.currentOrder,
        timeWork: Number(timeWork),
      },
    }));
  }

  onChangeStatus(e) {
    const status = e.target.value;

    this.setState((prevState) => ({
      currentOrder: {
        ...prevState.currentOrder,
        status: status,
      },
    }));
  }

  updateStatus(status) {
    OrderDataService.update(this.state.currentOrder.id, {
      status: status,
    })
      .then(() => {
        this.setState((prevState) => ({
          currentOrder: {
            ...prevState.currentOrder,
            status: status,
          },
          message: "The status was updated successfully!",
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateOrder() {
    const data = {
      address: this.state.currentOrder.address,
      comments: this.state.currentOrder.comments,
      timeWork: this.state.currentOrder.timeWork,
      status: this.state.currentOrder.status,
      requestedDateTime: this.state.currentOrder.requestedDateTime,
    };

    OrderDataService.update(this.state.currentOrder.id, data)
      .then(() => {
        this.setState({
          message: "Заказ успешно обновлен",
        });
      })
      .catch((e) => {
        console.log(e);
      });

  }

  RejectOrder() {

    this.setState(function (prevState) {
      return {
        currentOrder: {
          ...prevState.currentOrder,
          status: 'rejected',
          rejectedDate: new Date(),
          rejectedByName: 'менеджер (через сайт)'
        },
      };
    });

    const data = {
      status: 'rejected',
      rejectedDate: new Date(),
      rejectedByName: 'менеджер (через сайт)'
    };

    OrderDataService.update(this.state.currentOrder.id, data)
      .then(() => {
        this.setState({
          message: "Заказ успешно отклонен",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  ApproveOrder() {

    const data = {
      status: 'approved',
      approvedDate: new Date(),
      // TODO: Add approvedById
      approvedByName: 'менеджер (через сайт)',
    };

    this.setState(function (prevState) {
      return {
        currentOrder: {
          ...prevState.currentOrder,
          data
        },
      };
    });

    OrderDataService.update(this.state.currentOrder.id, data)
      .then(() => {
        this.setState(function (prevState) {
          return {
            currentOrder: {
              ...prevState.currentOrder,
              message: "заказ успешно одобрен",
              data
            }
          }
        }
        )
      }
      )
      .catch((e) => {
        console.log(e);
      });

  }

  deleteOrder() {
    OrderDataService.delete(this.state.currentOrder.id)
      .then(() => {
        this.props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentOrder } = this.state;

    return (
      <div>
        <h4>Заказ</h4>
        {
          currentOrder ? (
            <div className="col-md" >
              <form>
                <div className="form-group">
                  <label htmlFor="address">Адрес</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    value={currentOrder.address ? currentOrder.address : ""}
                    onChange={this.onChangeAddress}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="comments">Комментарий</label>
                  <input
                    type="text"
                    className="form-control"
                    id="comments"
                    value={currentOrder.comments ? currentOrder.comments : ""}
                    onChange={this.onChangeComments}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="comments">Планируемое время работы</label>
                  <input
                    type="number"
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    className="form-control"
                    id="timeWork"
                    minLength={1}
                    maxLength={4}
                    value={currentOrder.timeWork}
                    // onChange={this.onChangeMinWorkHours}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="comments">Начало заказа</label>
                </div>

                <div className="form-group">
                  <DatePicker
                    selected={currentOrder.requestedDateTime}
                    // onChange={this.onChangeDateTime}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="время"
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 14)}
                    locale="ru"
                    dateFormat="dd/MM/yyyy hh:mm"
                  />
                </div>
              </form>
              <button
                className="badge badge-danger mr-2"
                onClick={(e) => { if (window.confirm('Вы точно хотите удалить')) this.deleteOrder(e) }}
              >
                удалить
                  </button>
              <button
                type="submit"
                className="badge badge-danger mr-2"
                onClick={(e) => { if (window.confirm('Вы точно хотите отклонить заказ')) this.RejectOrder(e) }}
              >
                Отклонить заказ
                    </button>
              <button
                type="submit"
                className="badge badge-info"
                onClick={(e) => { if (window.confirm('Вы точно хотите применить изменения')) this.updateOrder(e) }}
              >
                Применить
                  </button>
              {/* {(currentOrder.status === 'created') ? 
              <button
                type="submit"
                className="badge badge-success"
                onClick={(e) => { if (window.confirm('Вы точно хотите одобрить заказ')) this.ApproveOrder(e) }}
              >
                Одобрить/Подтвердить заказ
                  </button> : ""}
              <p>{this.state.message}</p> */}
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={(currentOrder.createdDate && currentOrder.status === 'created') || currentOrder.status === 'approved' || currentOrder.status === "in_progress" ? new Date(currentOrder.createdDate['seconds'] * 1000).toLocaleString() : ""}
                  iconStyle={(currentOrder.createdDate && currentOrder.status === 'created') || currentOrder.status === 'approved' || currentOrder.status === "in_progress" || currentOrder.status === 'done' ? { background: 'rgb(50,205,50)', color: '#fff' } : { background: 'rgb(111, 111, 111)', color: '#fff' }}
                  icon={<i className="icofont icofont-angry-monster"></i>}
                >
                  <h4 className="vertical-timeline-element-title">{(currentOrder.createdDate && currentOrder.status === 'created') || currentOrder.status === 'approved' || currentOrder.status === "in_progress" || currentOrder.status === 'done' ? 'Заказ создан' : 'Заказа отклонен'}</h4>
                  <p>
                    {currentOrder.createdByName}
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={currentOrder.approvedDate ? new Date(currentOrder.approvedDate['seconds'] * 1000).toLocaleString() : ""}
                  iconStyle={(currentOrder.approvedDate && currentOrder.status === 'approved') || currentOrder.status === 'done' || currentOrder.status === "in_progress" ? { background: 'rgb(50,205,50)', color: '#fff' } : { background: 'rgb(111, 111, 111)', color: '#fff' }}
                  icon={<i className="icofont icofont-open-eye"></i>}
                >
                  <h4 className="vertical-timeline-element-title">{(currentOrder.approvedDate && currentOrder.status === 'approved') || currentOrder.status === 'done' || currentOrder.status === "in_progress" ? "Заказ одобрен" : "Ждем подтверждение заказа"}</h4>
                  <p>
                    {currentOrder.approvedByName}
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={currentOrder.startedDate ? new Date(currentOrder.startedDate['seconds'] * 1000).toLocaleString() : ""}
                  iconStyle={currentOrder.startedDate ? { background: 'rgb(50,205,50)', color: '#fff' } : { background: 'rgb(111, 111, 111)', color: '#fff' }}
                >
                  <h4 className="vertical-timeline-element-title">{currentOrder.startedDate ? "Заказ в работе" : "Заказ в работе"}</h4>
                  <p>
                    {currentOrder.startedByName}
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  date={currentOrder.completedDate ? new Date(currentOrder.completedDate['seconds'] * 1000).toLocaleString() : ""}
                  iconStyle={currentOrder.completedDate ? { background: 'rgb(50,205,50)', color: '#fff' } : { background: 'rgb(111, 111, 111)', color: '#fff' }}
                >
                  <h4 className="vertical-timeline-element-title">{currentOrder.completedDate ? "Заказ выполнен:" : "Заказ выполнен"}</h4>
                  <p>
                    {currentOrder.completedByName}
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          ) : (
            <div>
              <br />
              <p>Нажмите на заказ...</p>
            </div>
          )
        }
      </div >
    );
  }
}
