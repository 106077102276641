import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { productColumns } from '../../../data/product-list';
import CarDataService from "../../../services/firebase.service";
import { Button } from 'reactstrap'

export default class CarList2 extends Component {
  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveCar = this.setActiveCar.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.handleDeleteCarClick = this.handleDeleteCarClick.bind(this);

    this.state = {
      cars: [],
      currentCar: null,
      currentIndex: -1,
    };
    this.unsubscribe = undefined;
  }

  handleDeleteCarClick() {
    console.log("this should delete checked cars")
  }

  componentDidMount() {
    this.unsubscribe = CarDataService.getAll().orderBy("name", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items) {
    let cars = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();
      cars.push({
        id: id,
        brand: data.brand,
        car_group: data.car_group,
        car_type: data.car_type,
        description: data.description,
        healthy: data.healthy,
        image_path: data.image_path,
        min_work_hours: data.min_work_hours,
        model: data.model,
        name: data.name,
        plate_number: data.plate_number,
        uuidDrivers: data.uuidDrivers,
        published: data.published,
      });
    });

    this.setState({
      cars: cars,
    });
  }

  refreshList() {
    this.setState({
      currentCar: null,
      currentIndex: -1,
    });
  }

  setActiveCar(car, index) {
    console.log("setActiveCar");
    this.setState({
      currentCar: car,
      currentIndex: index,
    });
  }

  render() {
    const { cars } = this.state;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    return (
      <Fragment>
        {/* <Breadcrumb title="Product List" parent="Ecommerce" /> */}
        <div className="container-fluid">
          <div className="row">
            {/* <!-- Individual column searching (text inputs) Starts--> */}
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Список техники </h5>
                  <Button className="btn-sm" color="danger" onClick={this.handleDeleteCarClick}>Удалить</Button>
                </div>
              </div>
              <div>
                <div className="card-body">
                  <div className="table-responsive product-table">
                    <DataTable
                      // subHeader
                      columns={productColumns}
                      data={cars}
                      pagination
                      selectableRows
                      highlightOnHover
                      selectableRowsNoSelectAll
                      onSelectedRowsChange={handleChange}
                    // onSelectedRowsChange={this.setActiveCar}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Individual column searching (text inputs) Ends--> */}
          </div>
        </div>
      </Fragment>
    );
  };
}

