import React, { Component } from "react";
import CarDataService from "../../services/firebase.service";
import userDataService from "../../services/firebase_user.service";
import { Label, Input, Button } from 'reactstrap'
import { Multiselect } from 'multiselect-react-dropdown';

export default class AddCar extends Component {

    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeImagePath = this.onChangeImagePath.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangePlateNumber = this.onChangePlateNumber.bind(this);
        this.onChangeBrand = this.onChangeBrand.bind(this);
        this.onChangeCarGroup = this.onChangeCarGroup.bind(this);
        this.onChangeCarType = this.onChangeCarType.bind(this);
        this.onChangeMinWorkHours = this.onChangeMinWorkHours.bind(this);
        this.onChangeModel = this.onChangeModel.bind(this);
        this.onChangeDefaultDriver = this.onChangeDefaultDriver.bind(this);
        this.updatePublished = this.updatePublished.bind(this);
        this.AddCar = this.AddCar.bind(this);
        this.deleteCar = this.deleteCar.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onRemove = this.onRemove.bind(this);

        this.state = {
            currentCar: {
                id: null,
                name: "test",
                image_path: "",
                description: "",
                brand: "",
                car_group: "eks_pogruzhik_kolesniy",
                car_type: "avto-kran",
                min_work_hours: 4,
                model: "",
                plate_number: "",
                uuidDrivers: [],
                seleted_drivers: [],
                all_drivers: [],
                default_driver: "",
                published: false,
            },
            message: "",
            nameIsValidated: false,
            imagePathIsValidated: false,
            descriptionIsValidated: false,
            brandIsValidated: false,
            carGroupIsValidated: false,
            carTypeIsValidate: false,
            defaultDriverIsValidate: false,
            minWorkHoursIsValidate: false,
            modelIsValidate: false,
            plateNumberIsValidate: false,
            errorsName: "",
            errorsImagePath: "",
            errorsDescription: "",
            errorsBrands: "",
            errorsCarGroup: "",
            errorsCarType: "",
            errorsDefaultDriver: "",
            errorsMinWorkHours: "",
            errorsModel: "",
            errorsPlateNumber: "",
        };
        this.unsubscribe = undefined;
    };

    componentDidMount() {
        this.setState({
            currentCar: { ...this.state.currentCar },
        });
        this.unsubscribe = userDataService.getAll().orderBy("username", "asc").onSnapshot(this.onDataChange);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        let all_drivers = [];

        items.forEach((item) => {
            let id = item.id;
            let data = item.data();
            all_drivers.push({
                id: id,
                username: data.username,
            });
        });

        this.setState({
            all_drivers: all_drivers,
        });
    }

    onChangeName(e) {
        const name = e.target.value;

        if (typeof name != 'undefined' && name.length > 1) {
            this.setState(
                {
                    nameIsValidated: true,
                    errorsName: ""
                }
            );
        } else {
            this.setState(
                {
                    nameIsValidated: false,
                    errorsName: "Введите болльше чем 1 символ"
                }
            );
        }

        this.setState(function (prevState) {
            return {
                currentCar: {
                    ...prevState.currentCar,
                    name: name,
                },
            };
        });
    }

    onChangeImagePath(e) {
        const image_path = e.target.value;

        if (typeof image_path != 'undefined' && image_path.length > 1 && image_path.includes('http')) {
            this.setState(
                {
                    imagePathIsValidated: true,
                    errorsImagePath: ""
                }
            );
        } else {
            this.setState(
                {
                    imagePathIsValidated: false,
                    errorsImagePath: "вставте ссылку на картинку"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                image_path: image_path,
            },
        }));
    }

    onChangeDescription(e) {
        const description = e.target.value;

        if (typeof description != 'undefined' && description.length > 1) {
            this.setState(
                {
                    descriptionIsValidated: true,
                    errorsDescription: ""
                }
            );
        } else {
            this.setState(
                {
                    descriptionIsValidated: false,
                    errorsDescription: "введите описание"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                description: description,
            },
        }));
    }

    onChangeBrand(e) {
        const brand = e.target.value;

        if (typeof brand != 'undefined' && brand.length > 1) {
            this.setState(
                {
                    brandIsValidated: true,
                    errorsBrands: ""
                }
            );
        } else {
            this.setState(
                {
                    brandIsValidated: false,
                    errorsBrands: "введите бренд техники"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                brand: brand,
            },
        }));
    }

    onChangeCarGroup(e) {
        const car_group = e.target.value;

        if (typeof car_group != 'undefined' && car_group.length > 1 && car_group != null) {
            this.setState(
                {
                    carGroupIsValidated: true,
                    errorsCarGroup: ""
                }
            );
        } else {
            this.setState(
                {
                    carGroupIsValidated: false,
                    errorsCarGroup: "ввыберите группу техники"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                car_group: car_group,
            },
        }));
    }

    onChangeCarType(e) {
        const car_type = e.target.value;

        if (typeof car_type != 'undefined' && car_type.length > 1 && car_type != null) {
            this.setState(
                {
                    carTypeIsValidate: true,
                    errorsCarType: ""
                }
            );
        } else {
            this.setState(
                {
                    carTypeIsValidate: false,
                    errorsCarType: "ввыберите тип техники"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                car_type: car_type,
            },
        }));
    }

    onChangeMinWorkHours(e) {
        const min_work_hours = e.target.value;

        if (typeof min_work_hours != 'undefined' && min_work_hours != null && min_work_hours >= 4) {
            this.setState(
                {
                    minWorkHoursIsValidate: true,
                    errorsMinWorkHours: ""
                }
            );
        } else {
            this.setState(
                {
                    minWorkHoursIsValidate: false,
                    errorsMinWorkHours: "укажите минимальное кол-во часов для заказа >= 4"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                min_work_hours: Number(min_work_hours),
            },
        }));
    }

    onChangeModel(e) {
        const model = e.target.value;

        if (typeof model != 'undefined' && model != null && model.length > 1) {
            this.setState(
                {
                    modelIsValidate: true,
                    errorsModel: ""
                }
            );
        } else {
            this.setState(
                {
                    modelIsValidate: false,
                    errorsModel: "укажите модель техники"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                model: model,
            },
        }));
    }

    onChangeDefaultDriver(e) {
        const default_driver = e.target.value;

        if (typeof default_driver != 'undefined' && default_driver != null) {
            this.setState(
                {
                    defaultDriverIsValidate: true,
                    errorsDefaultDriver: ""
                }
            );
        } else {
            this.setState(
                {
                    defaultDriverIsValidate: false,
                    errorsDefaultDriver: "выберите основного водителя"
                }
            );
        }
        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                default_driver: default_driver,
            },
        }));
    }

    onChangePlateNumber(e) {
        const plate_number = e.target.value;

        if (typeof plate_number != 'undefined' && plate_number.length > 1) {
            this.setState(
                {
                    plateNumberIsValidate: true,
                    errorsPlateNumber: ""
                }
            );
        } else {
            this.setState(
                {
                    plateNumberIsValidate: false,
                    errorsPlateNumber: "ввыберите номерной знак техники"
                }
            );
        }

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                plate_number: plate_number,
            },
        }));
    }

    // Drivers Multi Select
    onSelect(selectedList, selectedItem) {

        const selected_list = selectedList;

        this.setState(function (prevState) {
            return {
                currentCar: {
                    ...prevState.currentCar,
                    seleted_drivers: selected_list,
                },
            };
        });

    }

    onRemove(selectedList, removedItem) {

        this.setState((prevState) => ({
            currentCar: {
                ...prevState.currentCar,
                seleted_drivers: selectedList,
            },
        }));
    }

    updatePublished(status) {
        CarDataService.update(this.state.currentCar.id, {
            published: status,
        })
            .then(() => {
                this.setState((prevState) => ({
                    currentCar: {
                        ...prevState.currentCar,
                        published: status,
                    },
                    message: "Статус был обновлен",
                }));
            })
            .catch((e) => {
                console.log(e);
            });
    }

    AddCar() {

        let driver_ids = []

        if (typeof this.state.currentCar.seleted_drivers !== 'undefined' || this.state.currentCar.seleted_drivers !== null) {

            driver_ids = this.state.currentCar.seleted_drivers.map(function (item) {
                return item['id'];
            });
        }

        if (typeof this.state.currentCar === 'undefined' || this.state.currentCar.name === null) {
            console.log("НЯМА currentCar");
            return;
        } else if (typeof this.state.currentCar.name === 'undefined' || this.state.currentCar.name.length <= 1) {
            console.log("name length <=1")
            return;
        } else if (typeof this.state.currentCar.image_path === 'undefined' || this.state.currentCar.image_path.length <= 1) {
            console.log("no image_path")
            return;
        } else if (typeof this.state.currentCar.description === 'undefined' || this.state.currentCar.description.length <= 1) {
            console.log("no description")
            return;
        } else if (typeof this.state.currentCar.brand === 'undefined' || this.state.currentCar.brand.length <= 1) {
            console.log("no brands")
            return;
        } else if (typeof this.state.currentCar.car_group === 'undefined' || this.state.currentCar.car_group.length <= 1) {
            console.log("no car_group")
            return;
        } else if (typeof this.state.currentCar.car_type === 'undefined' || this.state.currentCar.car_type.length <= 1) {
            console.log("no car_type")
            return;
        } else if (typeof this.state.currentCar.default_driver === 'undefined' || this.state.currentCar.default_driver.length <= 1) {
            console.log("no default_driver")
            return;
        } else if (typeof this.state.currentCar.min_work_hours === 'undefined' || this.state.currentCar.min_work_hours.length < 1) {
            console.log("no min_work_hours")
            return;
        } else if (typeof this.state.currentCar.model === 'undefined' || this.state.currentCar.model.length <= 1) {
            console.log("no model")
            return;
        } else if (typeof this.state.currentCar.plate_number === 'undefined' || this.state.currentCar.plate_number.length <= 1) {
            console.log("no plate_number")
            return;
        } else {
            const data = {
                name: this.state.currentCar.name,
                image_path: this.state.currentCar.image_path,
                description: this.state.currentCar.description,
                brand: this.state.currentCar.brand,
                car_group: this.state.currentCar.car_group,
                car_type: this.state.currentCar.car_type,
                healthy: true,
                min_work_hours: this.state.currentCar.min_work_hours,
                model: this.state.currentCar.model,
                default_driver: this.state.currentCar.default_driver,
                plate_number: this.state.currentCar.plate_number,
                uidDrivers: driver_ids,
            };

            CarDataService.create(data)
                .then(() => {
                    this.setState({
                        message: "Техника добавлена",
                    });
                    this.props.history.push('/listcars');
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    deleteCar() {
        CarDataService.delete(this.state.currentCar.id)
            .then(() => {
                this.props.refreshList();
            })
            .catch((e) => {
                console.log(e);
            });
    }

    render() {
        const { all_drivers } = this.state
        const validatedStyle = { border: '1px solid black' };
        const notValidatedStyle = { border: '1px solid red' };

        let inputStyleName = validatedStyle;
        let inputStyleImagePath = validatedStyle;
        let inputStyleDescription = validatedStyle;
        let inputStyleBrand = validatedStyle;
        let inputStyleCarGroup = validatedStyle;
        let inputStyleCarType = validatedStyle;
        let inputStyleDefaulDriver = validatedStyle;
        let inputStyleMinWorkHours = validatedStyle;
        let inputStyleModel = validatedStyle;
        let inputStylePlateNumber = validatedStyle;

        const nameIsValidated = this.state.nameIsValidated;
        const errorsName = this.state.errorsName;

        const imagePathIsValidated = this.state.imagePathIsValidated;
        const errorsImagePath = this.state.errorsImagePath;

        const descriptionIsValidated = this.state.descriptionIsValidated;
        const errorsDescription = this.state.errorsDescription;

        const brandIsValidated = this.state.brandIsValidated;
        const errorsBrands = this.state.errorsBrands;

        const carGroupIsValidated = this.state.carGroupIsValidated;
        const errorsCarGroup = this.state.errorsCarGroup;

        const carTypeIsValidate = this.state.carTypeIsValidate;
        const errorsCarType = this.state.errorsCarType;

        const defaultDriverIsValidate = this.state.defaultDriverIsValidate;
        const errorsDefaultDriver = this.state.errorsDefaultDriver;

        const minWorkHoursIsValidate = this.state.minWorkHoursIsValidate;
        const errorsMinWorkHours = this.state.errorsMinWorkHours;

        const modelIsValidate = this.state.modelIsValidate;
        const errorsModel = this.state.errorsModel;

        const plateNumberIsValidate = this.state.plateNumberIsValidate;
        const errorsPlateNumber = this.state.errorsPlateNumber;

        if (nameIsValidated) {
            inputStyleName = validatedStyle;
        } else {
            inputStyleName = notValidatedStyle;
        }

        if (imagePathIsValidated) {
            inputStyleImagePath = validatedStyle;
        } else {
            inputStyleImagePath = notValidatedStyle;
        }

        if (descriptionIsValidated) {
            inputStyleDescription = validatedStyle;
        } else {
            inputStyleDescription = notValidatedStyle;
        }

        if (brandIsValidated) {
            inputStyleBrand = validatedStyle;
        } else {
            inputStyleBrand = notValidatedStyle;
        }

        if (carGroupIsValidated) {
            inputStyleCarGroup = validatedStyle;
        } else {
            inputStyleCarGroup = notValidatedStyle;
        }

        if (carTypeIsValidate) {
            inputStyleCarType = validatedStyle;
        } else {
            inputStyleCarType = notValidatedStyle;
        }

        if (defaultDriverIsValidate) {
            inputStyleDefaulDriver = validatedStyle;
        } else {
            inputStyleDefaulDriver = notValidatedStyle;
        }

        if (minWorkHoursIsValidate) {
            inputStyleMinWorkHours = validatedStyle;
        } else {
            inputStyleMinWorkHours = notValidatedStyle;

        }

        if (modelIsValidate) {
            inputStyleModel = validatedStyle;
        } else {
            inputStyleModel = notValidatedStyle;
        }

        if (plateNumberIsValidate) {
            inputStylePlateNumber = validatedStyle;
        } else {
            inputStylePlateNumber = notValidatedStyle;
        }

        return (
            <div>
                <br></br>
                <h5>Добавить технику</h5>
                <div className="edit-form">
                    <form
                        onSubmit={this.AddCar}
                    >
                        <div className="form-group">
                            <label htmlFor="name">название</label>
                            <input
                                type="text"
                                style={inputStyleName}
                                className="form-control"
                                placeholder="а\кр. КАМАЗ 43253 АВ 50 16-7"
                                id="name"
                                minLength={1}
                                value={this.state.name}
                                onChange={this.onChangeName}
                            />
                            <span className='error'>{errorsName}</span>
                            <div></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="image_path">Ссылка на кратинку</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="https://storage.googleapis.com/dzetruck-cars/cars/kran-av-5016-7.jpg"
                                style={inputStyleImagePath}
                                id="image_path"
                                minLength={8}
                                value={this.state.image_path}
                                onChange={this.onChangeImagePath}
                            />
                            <span className='error'>{errorsImagePath}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">описание</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Двухосный бортовой грузовой автомобиль-тягач.Обновлённая версия исходного КамАЗ-4325.
                                Имеет аналогичный КамАЗу-5320 стандартный типоразмер, меньший чем базовый КамАЗ-5325.
                                Предназначен в том числе для работы автопоездом с прицепом ГКБ-8350 такого же типоразмера."
                                style={inputStyleDescription}
                                id="description"
                                value={this.state.description}
                                onChange={this.onChangeDescription}
                            />
                        </div>
                        <span className='error'>{errorsDescription}</span>
                        <div className="form-group">
                            <label htmlFor="brand">Бренд</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="KAMAZ"
                                style={inputStyleBrand}
                                id="brand"
                                value={this.state.brand}
                                onChange={this.onChangeBrand}
                            />
                        </div>

                        <span className='error'>{errorsBrands}</span>
                        <div className="form-group">
                            <Label for="car_group">Группа техники</Label>
                            <Input
                                type="select"
                                name="select"
                                style={inputStyleCarGroup}
                                id="car_group"
                                value={this.state.car_group}
                                defaultValue="eks_pogruzhik_kolesniy"
                                onChange={this.onChangeCarGroup}>
                                <option>eks_pogruzhik_kolesniy</option>
                                <option>eks_povorotniy</option>
                                <option>lift_truck</option>
                                <option>perevozka_10_20_30t</option>
                                <option>perevozka_light</option>
                                <option>pogruzchik</option>
                                <option>road_tools</option>
                            </Input>
                        </div>
                        <span className='error'>{errorsCarGroup}</span>
                        <div className="form-group">
                            <Label for="car_type">Тип техники</Label>
                            <Input
                                type="select"
                                name="select"
                                style={inputStyleCarType}
                                id="car_type"
                                defaultValue="avto-kran"
                                value={this.state.car_type}
                                onChange={this.onChangeCarType}>
                                <option>avto-kran</option>
                                <option>avtograyder</option>
                                <option>betonodrobilka</option>
                                <option>drobilka</option>
                                <option>excavator</option>
                                <option>excavator-pogruzchik</option>
                                <option>katok</option>
                                <option>kran</option>
                                <option>perevozchik</option>
                                <option>samosval</option>
                            </Input>
                        </div>
                        <span className='error'>{errorsCarType}</span>
                        <div className="form-group">
                            <label htmlFor="min_work_hours">Минимальное количество часов заказа для данной техники</label>
                            <input
                                type="number"
                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                placeholder="Введите минимальное количесво часов для заказа техники, например 4 или 8"
                                className="form-control"
                                style={inputStyleMinWorkHours}
                                id="min_work_hours"
                                minLength={1}
                                maxLength={4}
                                value={this.state.min_work_hours}
                                onChange={this.onChangeMinWorkHours}
                            />
                        </div>

                        <span className='error'>{errorsMinWorkHours}</span>
                        <div className="form-group">
                            <label htmlFor="model">Модель</label>
                            <input
                                type="text"
                                placeholder="а\кр. КАМАЗ 43253 АВ 50 16-7"
                                className="form-control"
                                style={inputStyleModel}
                                id="model"
                                value={this.state.model}
                                onChange={this.onChangeModel}
                            />
                        </div>

                        <span className='error'>{errorsModel}</span>

                        <div className="form-group">
                            <label htmlFor="plate_number">Номерной знак</label>
                            <input
                                type="text"
                                placeholder="АВ 50 16-7"
                                className="form-control"
                                style={inputStylePlateNumber}
                                id="plate_number"
                                value={this.state.plate_number}
                                onChange={this.onChangePlateNumber}
                            />
                        </div>
                        <span className='error'>{errorsPlateNumber}</span>
                        <div className="form-group">
                            <label htmlFor="default_driver">Основной водитель</label>
                            <Input
                                type="select"
                                name="select"
                                className="form-control"
                                style={inputStyleDefaulDriver}
                                id="default_driver"
                                value={this.state.default_driver}
                                onChange={this.onChangeDefaultDriver}>
                                {all_drivers && all_drivers.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.username}</option>;
                                })}
                            </Input>
                        </div>
                        <span className='error'>{errorsDefaultDriver}</span>
                        <label>Дополнительные водители</label>
                        <Multiselect
                            options={all_drivers} // Options to display in the dropdown
                            selectedValues={this.state.seleted_drivers || [""]} //  ["DRIVER1","DRIVER2"] ==> ""
                            onSelect={this.onSelect} // Function will trigger on select event
                            onRemove={this.onRemove} // Function will trigger on remove event
                            displayValue="username" // Property name to display in the dropdown options
                            placeholder="Поиск"
                        />
                        <div className="form-group">
                            <label>
                                <strong>Статус:</strong>
                            </label>
                        </div>
                    </form>
                    {" "}
                    <Button
                        color="secondary" className="btn-sm"
                        onClick={this.AddCar}
                    >
                        Добавить
                        </Button>
                    {" "}
                    <p>{this.state.message}</p>
                </div>
            </div>
        );
    }
}
