import firebase from "../data/config";

const db = firebase.collection("/cars");

class DataService {
  getAll() {
    return db;
  }

  get_data(car) {
    return db.doc(car).get();
  }

  create(car) {
    return db.add(car);
  }

  update(id, value) {
    return db.doc(id).update(value);
  }

  delete(id) {
    return db.doc(id).delete();
  }
}

export default new DataService();
