import React, { Component } from "react";
import UserDataService from "../../services/firebase_user.service";
import { Label, Input, Button } from "reactstrap";
import { Alert } from "reactstrap";

export default class User extends Component {
  constructor(props) {
    super(props);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangePatronymic = this.onChangePatronymic.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeRole = this.onChangeRole.bind(this);
    this.updatePublished = this.updatePublished.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.state = {
      currentUser: {
        id: null,
        // username: "",
        phone: "",
        email: "",
        roles: "",
        first_name: " ",
        last_name: " ",
        patronymic: " ",
      },
      message: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { user } = nextProps;
    if (prevState.currentUser.id !== user.id) {
      return {
        currentUser: user,
        message: "",
      };
    }

    return prevState.currentUser;
  }

  componentDidMount() {
    this.setState({
      currentUser: this.props.user,
    });
  }

  onChangeFirstName(e) {
    const first_name = e.target.value;

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          first_name: first_name,
        },
      };
    });
  }

  onChangeLastName(e) {
    const last_name = e.target.value;

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          last_name: last_name,
        },
      };
    });
  }

  onChangePatronymic(e) {
    const patronymic = e.target.value;

    this.setState(function (prevState) {
      return {
        currentUser: {
          ...prevState.currentUser,
          patronymic: patronymic,
        },
      };
    });
  }

  onChangePhone(e) {
    const phone = e.target.value;

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        phone: phone,
      },
    }));
  }

  onChangeEmail(e) {
    const email = e.target.value;

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        email: email,
      },
    }));
  }

  onChangeRole(e) {
    const roles = e.target.value;

    this.setState((prevState) => ({
      currentUser: {
        ...prevState.currentUser,
        roles: roles,
      },
    }));
  }
  updatePublished(status) {
    UserDataService.update(this.state.currentUser.id, {
      published: status,
    })
      .then(() => {
        this.setState((prevState) => ({
          currentUser: {
            ...prevState.currentUser,
            published: status,
          },
          message: "The status was updated successfully!",
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateUser() {
    const data = {
      // username: this.state.currentUser.username,
      first_name: this.state.currentUser.first_name,
      last_name: this.state.currentUser.last_name,
      patronymic: this.state.currentUser.patronymic,
      phone: this.state.currentUser.phone,
      email: this.state.currentUser.email,
      roles: this.state.currentUser.roles,
    };

    UserDataService.update(this.state.currentUser.id, data)
      .then(() => {
        this.setState({
          message: "Пользователь успешно обновлен",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteUser() {
    UserDataService.delete(this.state.currentUser.id)
      .then(() => {
        this.props.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        <h4>Пользователь</h4>
        {currentUser ? (
          <div className="edit-form">
            <form>
              <div className="form-group">
                <label htmlFor="last_name">Фамилия</label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  value={currentUser.last_name}
                  onChange={this.onChangeLastName}
                />
                <div></div>
              </div>
              <div className="form-group">
                <label htmlFor="first_name">Имя</label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  value={currentUser.first_name}
                  onChange={this.onChangeFirstName}
                />
                <div></div>
              </div>
              <div className="form-group">
                <label htmlFor="patronymic">Отчество</label>
                <input
                  type="text"
                  className="form-control"
                  id="patronymic"
                  value={currentUser.patronymic}
                  onChange={this.onChangePatronymic}
                />
                <div></div>
              </div>
              <div className="form-group">
                <label htmlFor="phone">Телефон</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={currentUser.phone}
                  onChange={this.onChangePhone}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Почта</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  value={currentUser.email}
                  onChange={this.onChangeEmail}
                />
              </div>
              <div className="form-group">
                <Label for="roles">Роль</Label>
                <Input
                  type="select"
                  name="select"
                  id="roles"
                  value={this.state.currentUser.roles}
                  onChange={this.onChangeRole}
                >
                  <option>client</option>
                  <option>manager</option>
                  <option>driver</option>
                </Input>
              </div>
            </form>
            <Button
              color="danger"
              className="btn-sm"
              onClick={(e) => {
                if (window.confirm("Вы точно хотите удалить пользователя"))
                  this.deleteUser(e);
              }}
            >
              Удалить
            </Button>{" "}
            <Button
              color="warning"
              className="btn-sm"
              type="submit"
              onClick={this.updateUser}
            >
              Применить
            </Button>{" "}
            <Alert color="light dark">{this.state.message}</Alert>
          </div>
        ) : (
          <div>
            <p>Выберите пользователя...</p>
          </div>
        )}
      </div>
    );
  }
}
