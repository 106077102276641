import React, { Component } from "react";
import userDataService from "../services/firebase_user.service";
import User from "../components/user/user"
import { Button, Row, Col } from 'reactstrap';
import ReactRoundedImage from "react-rounded-image";
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import db from '../data/config';

export default class usersList extends Component {

  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveuser = this.setActiveuser.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.handleAddUserClick = this.handleAddUserClick.bind(this);
    this.imageClick = this.imageClick.bind(this);

    this.state = {
      users: [],
      currentUser: null,
      currentIndex: -1,
      UserRoleStatus: "all",
    };

    this.unsubscribe = undefined;
  }

  componentDidMount() {
    this.unsubscribe = userDataService.getAll().orderBy("username", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items) {
    let users = [];

    items.forEach((item) => {
      let id = item.id;
      let data = item.data();
      users.push({
        id: id,
        username: data.username,
        first_name: (typeof data.first_name === 'undefined') ? "" : data.first_name,
        last_name: (typeof data.last_name === 'undefined') ? "" : data.last_name,
        patronymic: (typeof data.patronymic === 'undefined') ? "" : data.patronymic, 
        phone: data.phone,
        email: data.email,
        roles: data.roles,
        healthy: data.healthy,
      });
    });

    this.setState({
      users: users,
    });
  }

  refreshList() {
    this.setState({
      currentUser: null,
      currentIndex: -1,
    });
  }

  setActiveuser(user, index) {
    this.setState({
      currentUser: user,
      currentIndex: index,
    });
  }

  imageClick(user_role) {
    this.setState({
      UserRoleStatus: user_role
    })
  }

  handleAddUserClick() {
    this.setState({ showUser: true });
    this.props.history.push('/adduser');
  }

  render() {
    const { users, currentUser, UserRoleStatus, currentIndex } = this.state;

    const count_of_healthy_drivers = users.filter(
      (s) => s.roles === "driver" && s.healthy === true
    ).length;

    const count_of_unhealthy_drivers = users.filter(
      (s) => s.roles === "driver" && s.healthy === false
    ).length;

    const count_of_clients = users.filter(
      (s) => s.roles === "client"
    ).length;

    const count_of_managers = users.filter(
      (s) => s.roles === "manager"
    ).length;

    const titleStyle = {
      'color': '#CC9933',
      // 'text-align': '-webkit-right',
    }

    const theme = createMuiTheme({
      palette: {
        primary: {
          // light: will be calculated from palette.primary.main,
          main: '#00cc36',
          // dark: will be calculated from palette.primary.main,
          // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
          main: '#cf000f',
          // dark: will be calculated from palette.secondary.main,
          contrastText: '#cf000f',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
    });

    const handleChange = id => e => {
      console.log("ID", id, e.target.value);
      const index = users.findIndex(element => element.id === id)
      const user_id = id
      let newarr = users[index] = { ...users[index], healthy: !users[index].healthy }
      UpdateUser(user_id, newarr);
    };

    function UpdateUser(user_id, user_object) {
      db.collection('user').doc(user_id).update(user_object);
    }

    return (
      <div>
        <br></br><br></br>
        <h5>Список пользователей</h5>
        <div className="list row">
          <Col md={6} lg="auto" xl="auto">
            <button><img src="https://storage.googleapis.com/dzetruck-cars/client.svg" height="70px" alt="client" onClick={() => this.imageClick("client")} /><br></br>{count_of_clients}</button>
            <button><img src="https://storage.googleapis.com/dzetruck-cars/manager.svg" height="70px" alt="manager" onClick={() => this.imageClick("manager")} /><br></br>{count_of_managers}</button>
            <button><img src="https://storage.googleapis.com/dzetruck-cars/driver.svg" height="70px" alt="driver" onClick={() => this.imageClick("driver")} /><br></br>{count_of_unhealthy_drivers} || {count_of_healthy_drivers}</button>
            <Row>
              <Col align="left">
                <Button
                  color="warning"
                  onClick={this.handleAddUserClick}
                  className="btn btn-primary btn-sm">Добавить пользователя
          </Button>
              </Col>
            </Row>
            <br></br>
            <div className="scroller">
              <ul className="list-group">
                {users &&
                  (UserRoleStatus === 'all' ? users : users.filter(e => e.roles === UserRoleStatus))
                    .map((user, index) => (
                      <li
                        className={"list-group-item " + (index === currentIndex ? "list-group-item-action list-group-item-warning" : "")}
                        onClick={() => this.setActiveuser(user, index)}
                        key={index}
                      >
                        <Row>
                          <Col md="auto">
                            {(UserRoleStatus === "driver") ? (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              > {(user.healthy !== true) ? "НЕ РАБОТАЕТ" : "РАБОТАЕТ"}</Typography>
                            ) : (
                              ""
                            )
                            }
                            <ReactRoundedImage
                              image="https://storage.googleapis.com/dzetruck-cars/WEB/icons/driver.png" // TODO: new photo field
                              imageWidth="90"
                              imageHeight="90"
                              roundedSize="13"
                            />
                            <br></br>
                            {
                              (UserRoleStatus === "driver") ? (
                                <ThemeProvider
                                  theme={theme}
                                >
                                  <Switch
                                    key={user.id}
                                    checked={user.healthy}
                                    color={user.id ? "primary" : "secondary"}
                                    onChange={handleChange(user.id)}
                                    name="healthy"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  />
                                </ThemeProvider>
                              )
                                :
                                <></>
                            }
                          </Col>
                          <Col style={titleStyle}>
                          ID: <br></br>
                          Фамилия: <br></br>
                          Имя: <br></br>
                          Роль: <br></br>
                          Телефон: <br></br>
                          Почта: <br></br>
                          </Col>
                          <Col style={{ textAlign: "right" }}>
                            {user.id} <br></br>
                            {user.last_name}<br></br>
                            {user.first_name}<br></br>
                            {user.roles}<br></br>
                            {user.phone}<br></br>
                            {user.email}<br></br>
                          </Col>
                        </Row>
                      </li>
                    ))}
              </ul>
            </div >
          </Col>
          <Col>
            <br></br><br></br><br></br><br></br>
            {currentUser ? (
              <User
                user={currentUser}
                refreshList={this.refreshList}
              />
            ) : (
              <div>
                <br />
                <p>Выберите водителя.......</p>
              </div>
            )}
          </Col>
        </div >
      </div>
    );
  }
}
