import React, { Component } from "react";
import Chart from "react-google-charts";
import OrderDataService from "../services/firebase_orders.service";

export default class GanttChart extends Component {

    constructor(props) {
        super(props);
        this.onDataChange = this.onDataChange.bind(this);

        this.state = {
            orders: [],
            cars: [],
            carsReady: false,
            filterStatus: "all",
            currentIndex: -1,
            chartImageURI: ""
        };

        this.unsubscribe = undefined;
    }


    componentDidMount() {
        console.log('Did Mount');
        this.unsubscribe = OrderDataService.getActive().onSnapshot(this.onDataChange);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onDataChange(items) {
        console.log(items);
        let orders = [];

        items.forEach((item) => {
            console.log(item.id);
            let id = item.id;
            let data = item.data();

            var date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            var time_options = { hour: '2-digit', minute: '2-digit' };

            const timestamp = data.requestedDateTime.toDate();

            var dt = new Date(data.requestedDateTime.toDate());
            new Date(data.requestedDateTime);
            dt.setHours(dt.getHours() + data.timeWork);

            orders.push({
                id: id, // id of the order/ticket
                driverUuid: data.driverUuid,
                createdBy: data.createdBy,
                createdDate: data.createdDate,
                createdByName: data.createdByName,
                approvedBy: data.approvedBy,
                approvedByName: data.approvedByName,
                approvedDate: data.approvedDate,
                completedBy: data.completedBy,
                completedByName: data.completedByName,
                completedDate: data.completedDate,
                startedBy: data.startedBy,
                startedByName: data.startedByName,
                startedDate: data.startedDate,
                startTime: data.startTime,
                endTime: data.endTime,
                timestamp: timestamp,
                dt: dt,
                time: timestamp.toLocaleTimeString([], time_options), // 15:00
                timeWork: data.timeWork,
                requestedDateTime: data.requestedDateTime.toDate(), // вторник, 2 марта 2021 г.
                date: timestamp.toLocaleDateString("ru-Ru", date_options),
                carUuid: data.carUuid,
                status: data.status,
            });
        });

        this.setState({
            orders: orders,
        });

    }

    render() {

        const { orders } = this.state;

        const columns = [
            { type: "string", label: "Task ID" },
            { type: "string", label: "Имя задачи" },
            { type: "date", label: "Начало заказа" },
            { type: "date", label: "Конец закада" },
            { type: "number", label: "Продолжительность заказа" },
            { type: "number", label: "Выполненые процент(пока не работает)" },
            { type: "string", label: "Dependencies" },
        ];

        const rows = orders.map((order) => (
            [
                order.id,
                order.driverUuid,
                new Date(order.timestamp),
                new Date(order.dt),
                null,
                100,
                null
            ]))

        return (

            <div>
                <Chart
                    is3D={true}
                    width={'100%'}
                    height={'800px'}
                    chartType="Gantt"
                    loader={<div>Loading Chart</div>}
                    data={[columns, ...rows]}
                    options={{
                        height: 800,
                        gantt: {
                            trackHeight: 30,
                        },
                    }}
                />
            </div>
        );
    }
}
